// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--BELRff0MnAxuvAGrOshF{width:550px;margin:15px 20px}.buttons--ElF1Q9fItm8Rah5m68bV{display:flex;justify-content:space-between}.linksContainer--myqBYRrA4f2dHzodWL2g{margin-bottom:10px}.links--ubmHrfaOB8m2vdJ0H6lN{display:flex;flex-direction:column}.link--f0aqs3IrU3k1sj0TSkTg{display:flex;align-items:center;padding:8px;justify-content:space-between;background:#cee6f2;margin-bottom:10px}.empty--O_PB1TQ1a4DsRNcLvy35{background:none}.linkName--iGU_U35hlNqck8Ho1rWE{display:flex;align-items:center;margin-right:5px}.notification--_fibiQMsLwkYIpI0yMsQ{margin:15px 0 30px 0;color:#fc2b32}", "",{"version":3,"sources":["webpack://./frontend/src/ReactFeatures/ClassifierFieldForm/Components/ClassifierFieldForm/style.module.scss"],"names":[],"mappings":"AAAA,+BACE,WAAA,CACA,gBAAA,CAEF,+BACE,YAAA,CACA,6BAAA,CAEF,sCACE,kBAAA,CAEF,6BACE,YAAA,CACA,qBAAA,CAEF,4BACE,YAAA,CACA,kBAAA,CACA,WAAA,CACA,6BAAA,CACA,kBAAA,CACA,kBAAA,CAEF,6BACE,eAAA,CAEF,gCACE,YAAA,CACA,kBAAA,CACA,gBAAA,CAGF,oCACE,oBAAA,CACA,aAAA","sourcesContent":[".wrapper {\n  width: 550px;\n  margin: 15px 20px;\n}\n.buttons {\n  display: flex;\n  justify-content: space-between;\n}\n.linksContainer {\n  margin-bottom: 10px;\n}\n.links {\n  display: flex;\n  flex-direction: column;\n}\n.link {\n  display: flex;\n  align-items: center;\n  padding: 8px;\n  justify-content: space-between;\n  background: #cee6f2;\n  margin-bottom: 10px;\n}\n.empty {\n  background: none;\n}\n.linkName {\n  display: flex;\n  align-items: center;\n  margin-right: 5px;\n}\n\n.notification {\n  margin: 15px 0 30px 0;\n  color: #fc2b32;\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = "wrapper--BELRff0MnAxuvAGrOshF";
export var buttons = "buttons--ElF1Q9fItm8Rah5m68bV";
export var linksContainer = "linksContainer--myqBYRrA4f2dHzodWL2g";
export var links = "links--ubmHrfaOB8m2vdJ0H6lN";
export var link = "link--f0aqs3IrU3k1sj0TSkTg";
export var empty = "empty--O_PB1TQ1a4DsRNcLvy35";
export var linkName = "linkName--iGU_U35hlNqck8Ho1rWE";
export var notification = "notification--_fibiQMsLwkYIpI0yMsQ";
export default ___CSS_LOADER_EXPORT___;
