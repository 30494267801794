import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Icon,
  Button,
  Checkbox,
  isEmpty,
  FormField,
  SwitcherContainer,
  REQUIRED_FIELD_ERROR
} from "@omnichat/arm_ui_kit";

import { Input, SubTitle } from "../../../../Components";
import FormFieldConnector from "../../../../Components/FormFieldConnector";
import { IValueProps } from "./ClassifierFieldValueForm.d";

import { ReactHookFormErrorHandler } from "../../../../Utils/HookForm.helper";

const CB = SwitcherContainer(Checkbox);

import * as s from "./style.module.scss";

const schema = Yup.object({
  name: Yup.string().required(REQUIRED_FIELD_ERROR),
  isAlwaysShow: Yup.boolean().required()
});

const defaultValues = {
  name: "",
  isAlwaysShow: false
};

export interface ValueForm extends Yup.InferType<typeof schema> {}

const ClassifierFieldValueForm: React.FunctionComponent<IValueProps> = ({
  fieldId,
  onEditLink,
  onRemoveLinks,
  onCreateLink,
  onRemoveField,
  loading,
  onSave,
  data,
  links,
  errorMessage
}): JSX.Element => {
  const methods = useForm<ValueForm>({
    defaultValues,
    resolver: yupResolver(schema)
  });
  const { handleSubmit, watch, reset } = methods;
  const isAlwaysShow = watch("isAlwaysShow");

  useEffect(() => {
    if (data) {
      reset({
        name: data.name || "",
        isAlwaysShow: data.isAlwaysShow || false
      });
    } else {
      reset(defaultValues);
    }
  }, [data, fieldId]);

  return (
    <FormProvider {...methods}>
      <div className={s["wrapper"]}>
        <SubTitle
          caption={data?.id ? "Редактирование значения" : "Создание значения"}
        />
        <FormFieldConnector
          label="Название"
          required
          name="name"
          Component={(p) => <Input {...p} />}
        />
        <FormFieldConnector
          name="isAlwaysShow"
          Component={(p) => (
            <CB
              {...p}
              type="slider"
              caption="Всегда отображать"
              checked={p.value}
            />
          )}
        />

        {!isAlwaysShow && data?.id && (
          <>
            {!isEmpty(links) && (
              <FormField label={"Зависимости от значений предыдущих полей"}>
                <div className={s["links"]}>
                  {links?.map((l, i) => (
                    <div key={i} className={s["link"]}>
                      <div
                        className={s["linkName"]}
                        onClick={() => onEditLink?.(i)}
                      >
                        {l}
                      </div>
                      <Icon
                        name="cross"
                        color="blue"
                        width="20px"
                        height="20px"
                        onClick={() => onRemoveLinks(i)}
                      />
                    </div>
                  ))}
                </div>
              </FormField>
            )}

            <Button
              disabled={loading}
              type="underline"
              theme="default"
              text="Добавить зависимость"
              onClick={() => onCreateLink()}
            />
          </>
        )}

        <div className={s["notification"]}>{errorMessage}</div>

        <div className={s["buttons"]}>
          <Button
            type="default"
            theme={"green"}
            onClick={handleSubmit(
              (data) => onSave(data),
              ReactHookFormErrorHandler
            )}
            text="Сохранить"
          />
          {data?.id && (
            <Button
              type="default"
              theme={"red"}
              onClick={() => onRemoveField()}
              text="Удалить"
            />
          )}
        </div>
      </div>
    </FormProvider>
  );
};

export default ClassifierFieldValueForm;
