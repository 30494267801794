import { createSelector } from "@reduxjs/toolkit";
import { isEmpty } from "@omnichat/arm_ui_kit";
import { StoreState } from "../../../ReactCore/store/types";
import { UserData as TypeUserData } from "../UserGroupsManager";
import { ALL, DELETED } from "../consts";

export const usersState = (state: StoreState.State) => {
  return state.Users;
};

export const loading = createSelector(usersState, (state) => {
  return isEmpty(state.loading);
});

export const getAllCheckboxValue = createSelector(usersState, (state) => {
  return state.checkAllUsers;
});

export const getSelectedUsers = createSelector(usersState, (state) => {
  return state.checkedUsers;
});

export const userList = createSelector(usersState, (state) => {
  const { checkedUsers, checkAllUsers } = state;

  return state.userList?.map((item: TypeUserData) => ({
    ...item,
    checked: !!checkedUsers.find((id) => item.id === id) || checkAllUsers
  }));
});

export const userData = createSelector(usersState, (state) => {
  return state.userData;
});

export const currentSkillGroup = createSelector(usersState, (state) => {
  return state.skillGroupList?.find(
    ({ id }) => id === state.currentSkillGroupId
  );
});

export const getQuery = createSelector(usersState, (state) => {
  return state.query;
});

export const getPageSkillgroups = createSelector(usersState, (state) => {
  return +state.currentSkillGroupPage;
});

export const getPageUsers = createSelector(usersState, (state) => {
  return +state.currentUsersPage;
});

export const skillGroupList = createSelector(usersState, (state) => {
  const { currentSkillGroupId } = state;

  return state.skillGroupList?.map((item) => ({
    ...item,
    current: item.id === currentSkillGroupId,
    editable: ![ALL, DELETED].includes(item.id)
  }));
});

export const skillGroupData = createSelector(usersState, (state) => {
  return state.skillGroupData;
});

export const getRestorePasswordHandling = createSelector(
  usersState,
  (state) => state.restorePasswordHandling
);
