import { eventChannel, END } from "redux-saga";
import {
  call,
  take,
  fork,
  cancelled,
  putResolve,
  race
} from "redux-saga/effects";
import * as Sentry from "@sentry/react";

import { WS_SEND } from "./Consts";
import WebsocketActions from "./Websocket.actions";
import { IWsOptions } from "./Websocket";

const regexWsSend = new RegExp(`^${WS_SEND}`);

// Используется для выбрасывания исключений, позволяет проще дебажить.
const dispatch = putResolve;

let instances: {
  [key: string]: {
    options?: IWsOptions;
    handle?: any;
    open?: any;
    socket?: any;
    socketChannel?: any;
    secretId?: any;
    destroy: any;
  };
} = {};

window.instances = instances

export const websocket = function() {
  function createInstance(options: IWsOptions, handle: () => {}) {
    if (!options) {
      console.error("Create WebsocketSingleton required options");
      return;
    }

    const name = `${options.socketPrefix}INSTANCE`;

    if (instances[name]) {
      console.error(`Websocket with name ${name} already exist`);
      return;
    }

    instances[name] = {
      options,
      handle,
      open,
      destroy: () => {
        destroy(name);
      }
    };

    return instances[name];
  }

  function destroy(name) {
    delete instances[name];
  }

  function createWebSocketConnection(instance) {
    return new Promise((resolve, reject) => {
      const socket = new WebSocket(instance.options.url);
      socket.onopen = () => resolve(socket);
      socket.onerror = (evt) => reject(evt);
    });
  }

  function createSocketChannel(instance) {
    return eventChannel((emit) => {
      instance.socket.onmessage = (event) => emit(event.data);
      instance.socket.onclose = () => emit(END);
      const unsubscribe = () => {
        instance.socket.onmessage = null;
      };
      return unsubscribe;
    });
  }

  function* sendMessage(instance) {
    while (true) {
      const { type, payload } = yield take((action) => {
        if(regexWsSend.test(action.type)) {
          Sentry.withScope((scope) => {
            scope.setExtra("Instances", { instances });
            Sentry.captureMessage(
              `Send websocket message type=${action.type} user=${core.user.id}`,
              "debug"
            );
          });
          return true
        }
        return false
      }
      );
      instance.socket.send(JSON.stringify(payload));
    }
  }

  function sendMessageRead(instance, guid) {
    instance.socket.send(
      JSON.stringify({
        callback: "message_read",
        guid: guid
      })
    );
  }

  function* receivedMessage(instance) {
    while (true) {
      const data = yield take(instance.socketChannel);
      const wsEvent = JSON.parse(data);
      instance.handle(wsEvent, () => {
        sendMessageRead(instance, wsEvent.guid);
      });
    }
  }

  function* sendHelloMessage(instance) {
    const mess = {
      callback: "connect",
      user_id: instance.options.userId,
      user_hash: instance.options.userHash,
      project_id: 1
    };
    instance.socket.send(JSON.stringify(mess));
  }

  function* listenForSocketMessages(instance) {
    const wsActions = WebsocketActions(instance.options.socketPrefix);
    try {
      // Запись в экземпляр созданных канала и соединения
      // для последующих обращений к ним извне
      instance.socket = yield call(createWebSocketConnection, instance);
      instance.socketChannel = yield call(createSocketChannel, instance);

      // Отправляем на сервер первый пакет "connect"
      yield sendHelloMessage(instance);

      // Говорим приложению, что появилось WebSocket соединение
      yield dispatch(wsActions.connectionSuccess({}));
      console.log("Websocket: Connected");

      Sentry.withScope((scope) => {
        scope.setExtra("Instances", { instances });
        Sentry.captureMessage(
          `Websocket Chat Connected user=${core.user.id}`,
          "debug"
        );
      });

      // Запуск гонки эффектов:
      //  * прослушивание входящих ws-пакетов
      //  * внутренних событий отправки пакетов
      while (true) {
        yield race([
          call(receivedMessage, instance),
          call(sendMessage, instance)
        ]);
      }
    } catch (error) {
      console.log("WebSocket: Error while connecting to the WebSocket");

      Sentry.withScope((scope) => {
        scope.setExtra("Error", { error, instances });
        Sentry.captureMessage(
          `WebSocket: Error while connecting to the WebSocket user=${core.user.id}`,
          "debug"
        );
      });

      yield dispatch(
        wsActions.connectionError({
          text: "Error while connecting to the WebSocket",
          desc: error
        })
      );
    } finally {
      if (yield cancelled()) {
        console.log("Websocket: Disconnecting...");
     
        yield close(instance);

        Sentry.withScope((scope) => {
          scope.setExtra("Error", { instances });
          Sentry.captureMessage(
            `"Websocket: Disconnected... user=${core.user.id}`,
            "debug"
          );
        });
      } else {
        yield dispatch(
          wsActions.connectionError("WebSocket disconnected incorrectly")         
        );
        
        Sentry.withScope((scope) => {
          scope.setExtra("Error", { instances });
          Sentry.captureMessage(
            `"WebSocket disconnected incorrectly user=${core.user.id}`,
            "debug"
          );
        });
      }
    }
  }

  function* open(instance) {
    instance.secretId = yield fork(listenForSocketMessages, instance);
  }

  function* close(instance) {
    // Закрываем канал
    yield instance.socketChannel.close();
    // Закрываем WebSocket соединение
    yield instance.socket.close();
    console.log("Websocket: Connection is closed");

    Sentry.captureMessage(
      `Websocket Chat Connection is closed user=${core.user.id}`,
      "debug"
    );

    Sentry.withScope((scope) => {
      scope.setExtra("SocketInstances", instances);
      Sentry.captureMessage(
        `Websocket Chat Connection is closed user=${core.user.id}`,
        "debug"
      );
    });

    instance = undefined;
  }

  return {
    getInstance: (options?: IWsOptions, handle?) => {
      return instances[options.socketPrefix] || createInstance(options, handle);
    }
  };
};
