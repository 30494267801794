import chat from "./msg_chat";
import stats from "./msg_stats";
import settings from "./msg_settings";
import React from "react";
import ReactDom from "react-dom";
import { Provider } from "react-redux";

import ThemeProvider from "../Components/ThemeProvider";
import { store } from "../ReactCore";
import { chatDestroy } from "./Constructors/ChatConstructor";
import initLayout from "./Constructors/LayoutConstructor";

import {
  sendUserOffline,
  sendUserlogoff,
  transferAllAppeals
} from "../ReactFeatures/OnlineToggle/Store/OnlineToggle.reducer";

export default {
  chat,
  stats,
  settings,

  lng: {
    chat: {},
    stats: {},
    settings: {}
  },

  websocket: {
    real_time_stats: {
      index: {
        0: "await_time",
        1: "user_count",
        2: "chat_count",
        3: "limits"
      },

      cache: { groups: {}, agents: {}, overall: {} },

      diff: function(arr1, arr2) {
        var output = {};

        for (var i in arr1) {
          if (arr2[i] === undefined) continue;

          if (typeof arr1[i] == "object") {
            output[i] = {};
            for (var j in arr1[i]) {
              if (arr2[i][j] === undefined) continue;

              if (typeof arr1[i][j] == "object") {
                output[i][j] = {};
                for (var k in arr1[i][j]) {
                  if (arr2[i][j][k] === undefined) continue;

                  if (arr1[i][j][k] !== arr2[i][j][k])
                    output[i][j][k] = arr2[i][j][k];
                }
              } else {
                if (arr1[i][j] !== arr2[i][j]) output[i][j] = arr2[i][j];
              }
            }
          } else {
            if (arr1[i] !== arr2[i]) output[i] = arr2[i];
          }
        }

        return output;
      },

      declOfNum: function(number, titles) {
        let cases = [2, 0, 1, 1, 1, 2];
        return titles[
          number % 100 > 4 && number % 100 < 20
            ? 2
            : cases[number % 10 < 5 ? number % 10 : 5]
        ];
      },

      update: function(type, data) {
        if (!$(".stats_agents_list")) {
          return;
        }
        var el,
          setAgentPos = function(type, elem) {
            var group_el = $(".stats_agents_list")
                .children()
                .first(),
              last_online_el = $(".stats_agents_list")
                .children()
                .filter(".agentOnline")
                .last().length
                ? $(".stats_agents_list")
                    .children()
                    .filter(".agentOnline")
                    .last()
                : null,
              last_offline_el = $(".stats_agents_list")
                .children()
                .filter(".agentOffline")
                .last().length
                ? $(".stats_agents_list")
                    .children()
                    .filter(".agentOffline")
                    .last()
                : last_online_el,
              last_online_el_pos = $(last_online_el).index(),
              last_offline_el_pos = $(last_offline_el).index(),
              elem_pos = $(elem).index();

            // список агентов на странице пуст
            if (!group_el.next().length) {
              group_el.after(elem);
            } else {
              // агент встал в онлайн
              if (type === "online" && elem_pos > last_online_el_pos) {
                $(elem).remove();
                if (last_online_el) {
                  // есть другие онлайн
                  last_online_el.after(elem);
                } else {
                  // нет других онлайн
                  group_el.after(elem);
                }
              }
              // агент встал в оффлайн
              if (type === "offline" && elem_pos < last_offline_el_pos) {
                $(elem).remove();
                if (last_offline_el) {
                  // есть другие оффлайн/онлайн
                  last_offline_el.after(elem);
                } else {
                  // нет других оффлайн/онлайн
                  group_el.after(elem);
                }
              }
              // агент разлогинился и идёт после онлайн/оффлайн агентов
              if (type === "logout" && elem_pos < last_offline_el_pos) {
                $(elem).remove();
                $(".stats_agents_list")
                  .children()
                  .last()
                  .after(elem);
              }
            }
          },
          setAgentOnline = function(elem) {
            setAgentPos("online", elem);
            el.removeClass("agentOffline").addClass("agentOnline");
          },
          setAgentOffline = function(elem) {
            setAgentPos("offline", elem);
            el.removeClass("agentOnline").addClass("agentOffline");
          };

        if (type == "groups") {
          for (var id in data) {
            el = $(".stats_agents_list").children("#stats_group_" + id);
            // Количество агентов онлайн
            if (typeof data[id].agents_online !== "undefined") {
              el.find("#stats_group_agents_" + id).html(
                data[id].agents_online === 0
                  ? "нет агентов"
                  : data[id].agents_online +
                      " " +
                      this.declOfNum(data[id].agents_online, [
                        "агент",
                        "агента",
                        "агентов"
                      ])
              );
            }
            // Количество открытых обращений
            if (data[id].appeals !== undefined) {
              el.find("#stats_group_appeals_" + id).html(
                data[id].appeals === 0
                  ? "нет обращений"
                  : data[id].appeals +
                      " " +
                      this.declOfNum(data[id].appeals, [
                        "обращение",
                        "обращения",
                        "обращений"
                      ])
              );
            }
            // Количество обращений в очереди и лимит очереди
            if (
              typeof data[id].queued.count !== "undefined" ||
              typeof data[id].queued.limit !== "undefined"
            ) {
              var limit =
                typeof data[id].queued.limit === "undefined"
                  ? this.cache.groups[id].queued.limit
                  : data[id].queued.limit;
              var count =
                typeof data[id].queued.count === "undefined"
                  ? this.cache.groups[id].queued.count
                  : data[id].queued.count;
              if (count > 0) {
                el.find("#stats_group_queue_count_" + id).html(
                  !limit ? count + " из ∞" : count + " из " + limit
                );
                el.find("#stats_group_queue_" + id).css({
                  height: (count > 0 ? (100 * count) / limit : "0") + "%"
                });
                el.addClass("groupQueue");
              } else {
                el.removeClass("groupQueue");
              }
            }
            // Максимальное время ожидания в очереди
            if (data[id].queued.time_max && data[id].queued.time_max.trim()) {
              el.find("#stats_group_queue_time").html(
                data[id].queued.time_max
                  ? data[id].queued.time_max
                  : this.cache.groups[id].queued.time_max
              );
            }
          }
        } else if (type == "overall") {
          el = $(".stats_agents_list").children("#stats_group_all");
          if (data.agents_online !== undefined)
            el.find("#stats_group_agents_all").html(
              data.agents_online === 0
                ? "нет агентов"
                : data.agents_online +
                    " " +
                    this.declOfNum(data.agents_online, [
                      "агент",
                      "агента",
                      "агентов"
                    ])
            );
          if (data.appeals !== undefined)
            el.find("#stats_group_appeals_all").html(
              data.appeals === 0
                ? "нет обращений"
                : data.appeals +
                    " " +
                    this.declOfNum(data.appeals, [
                      "обращение",
                      "обращения",
                      "обращений"
                    ])
            );
        } else if (type == "agents") {
          var el_group = $(".stats_agents_list").children(".stats_group_wrap"),
            current_group = core.nav.url.params.skill,
            data_group = this.cache.groups[current_group],
            group_stats_el = parent.find(".stats_agentGroup_state");

          el_group
            .find("#stats_group_agents_all")
            .html(
              data_group.agents_online === 0
                ? "нет агентов"
                : data_group.agents_online +
                    " " +
                    this.declOfNum(data_group.agents_online, [
                      "агент",
                      "агента",
                      "агентов"
                    ])
            );
          el_group
            .find("#stats_group_appeals_all")
            .html(
              data_group.appeals === 0
                ? "нет обращений"
                : data_group.appeals +
                    " " +
                    this.declOfNum(data_group.appeals, [
                      "обращение",
                      "обращения",
                      "обращений"
                    ])
            );

          if (data_group.queued.count > 0) {
            el_group
              .find("#stats_group_queue_count_" + current_group)
              .html(
                !data_group.queued.limit
                  ? data_group.queued.count + " из ∞"
                  : data_group.queued.count + " из " + data_group.queued.limit
              );
            el_group.find("#stats_group_queue_" + current_group).css({
              height:
                (data_group.queued.count > 0
                  ? (100 * data_group.queued.count) / data_group.queued.limit
                  : "0") + "%"
            });
          } else {
            el_group.removeClass("groupQueue");
          }

          var agents = data;
          // присланные агенты из real time stats vs агенты на странице
          for (var agent_id in agents) {
            el = $(".stats_agents_list").children("#stats_agent_" + agent_id);
            // такого агента нет на странице, двигать некого, продолжаем
            if (!el[0]) {
              continue;
            }

            if (!agents[agent_id].status || agents[agent_id].status == 3) {
              setAgentPos("logout", el);
              el.removeClass("agentOnline agentOffline");
              el.find("#stats_agent_appeals_" + agent_id).html("нет обращений");
            } else {
              if (agents[agent_id].status == 1 && !el.hasClass("agentOnline")) {
                setAgentOnline(el);
              } else if (
                agents[agent_id].status == 2 &&
                !el.hasClass("agentOffline")
              ) {
                setAgentOffline(el);
              }

              el.find("#stats_agent_appeals_" + agent_id).html(
                agents[agent_id].appeals_by_group &&
                  agents[agent_id].appeals_by_group[current_group]
                  ? agents[agent_id].appeals_by_group[current_group] +
                      " " +
                      this.declOfNum(
                        agents[agent_id].appeals_by_group[current_group],
                        ["обращение", "обращения", "обращений"]
                      )
                  : "нет обращений"
              );

              el.find("#stats_agent_load_count_" + agent_id).html(
                !agents[agent_id].appeals_limit
                  ? agents[agent_id].appeals + " из ∞"
                  : agents[agent_id].appeals +
                      " из " +
                      agents[agent_id].appeals_limit
              );

              el.find("#stats_agent_load_" + agent_id).css({
                height:
                  100 *
                    (agents[agent_id].appeals /
                      agents[agent_id].appeals_limit) +
                  "%"
              });

              if (!agents[agent_id].appeals_limit) {
                el.addClass("loadUnlim");
              } else {
                el.removeClass("loadUnlim");
              }
            }
          }
        }
      },

      onmessage: function(data) {
        if (!Object.keys(this.cache.groups).length) {
          this.cache.groups = data.groups;
        }

        if (!Object.keys(this.cache.agents).length) {
          this.cache.agents = data.agents;
        }

        if (!Object.keys(this.cache.overall).length) {
          this.cache.overall = data.overall;
        }

        if (core.nav.url.sec !== "stats") {
          return false;
        }

        // если skill не указан, то обновляем только группы
        if (
          core.nav.url.params.skill == undefined ||
          core.nav.url.params.skill == "all"
        ) {
          this.cache.agents = data.agents; // Перезаписываем кэш по агентам
          this.update("groups", this.diff(this.cache.groups, data.groups)); // Проверяем разницу в группах
          this.cache.groups = data.groups;
        } else {
          // если skill указан, то обновляем только агентов
          this.cache.groups = data.groups; // Перезаписываем кэш по группам
          this.update("agents", data.agents); // Проверяем разницу в агентах
          this.cache.agents = data.agents;
        }

        this.update("overall", this.diff(this.cache.overall, data.overall)); // Проверяем разницу по проекту
        this.cache.overall = data.overall;
      }
    },

    new_message: {
      count_delay: 0
    },

    logout: {
      onmessage: function(resp) {
        clearInterval(workspace.watch.interval);
        core.websocket.disconnect();
        workspace.reauth.init();
      }
    },

    create_report_response: {
      onmessage: function(resp) {
        var message;
        if ($("#modal_msg_reportSettings").length) {
          if (resp.status == "processed") {
            message = msg.lng.stats.appealsExportProcessed;
          } else {
            message = msg.lng.stats.appealsProcessingError;
          }
          setTimeout(function() {
            msg.stats.report_update(resp, message);
          }, 3000);
        }
      }
    }
  },

  draw: {
    cache: {
      layout: {
        mask: "#msg-module",
        parent: "module"
      },

      module_loading: {
        mask: ".wsModule_loading",
        parent: "layout"
      },

      body: {
        mask: ".body",
        parent: "layout"
      }
    }
  },

  nav: {
    init: function() {
      initLayout();
      core.tools.loadCode("photoswipe.css");
      core.tools.loadCode("window.js");

      $(window).on({
        "core.storage.msg_appeal_count": msg.state.appeal_count_sync,
        "core.storage.msg_appeal_connect": msg.tab.handler,
        "core.storage.tab_id": msg.tab.handler
      });
      msg.tab.checkLockedOnline();

      $(window)
        .on("focus", function() {
          $(window).data("focused", true);
        })
        .on("blur", function() {
          $(window).data("focused", false);
        });
    },

    destroy: function() {
      $(window).off({
        "core.storage.msg_appeal_count": msg.state.appeal_count_sync,
        "core.storage.msg_appeal_connect": msg.tab.handler,
        "core.storage.tab_id": msg.tab.handler
      });
    },

    before: function(data) {
      if (data.urlCur.sec !== data.url.sec) {
        $(".body").addClass("load");
      }
      msg.tab.checkLockedOnline();
      if (core.draw.window_ids.length) {
        $(".modal-container").modal("close");
      }
      msg.skill_count = 1;
    },

    after: function(data) {}
  },

  state: {
    appeal_connect: false,

    appeal_connect_sync: function() {
      msg.state.appeal_connect = core.storage.get("msg_appeal_connect");
    },

    tab_active: true,

    appeal_count: 0,

    appeal_count_sync: function() {
      msg.state.appeal_count = core.storage.get("msg_appeal_count");
    }
  },

  tab: {
    // Блокировка дубля окна оператора и открытия интерфейса статистики при статусе Online,
    // либо назначеных обращениях

    locked: false,

    handler: function(e) {
      if (!msg.state.tab_active) return false;
      var lock = false;

      if (
        e.name == "tab_id" &&
        e.new_val != core.websocket.tab_id &&
        (core.nav.url.sec == "chat" ||
          (core.nav.url.sec == "stats" &&
            (msg.state.appeal_count > 0 ||
              msg.state.appeal_connect ||
              (msg.state.appeal_count === 0 && !msg.state.appeal_connect))))
      ) {
        lock = true;
      } else if (e.name == "msg_appeal_connect" && e.new_val == "true") {
        if (
          core.websocket.tab_id &&
          core.websocket.tab_id != core.storage.get("tab_id")
        ) {
          lock = true;
        }
      }

      if (lock) {
        if (
          core.nav.url.sec == "chat" ||
          (core.nav.url.sec == "stats" &&
            //msg.state.appeal_count === 0 &&
            !msg.state.appeal_connect)
        ) {
          msg.tab.lock_page();
        } else {
          msg.tab.lock_modal();
        }
      }
    },

    check_new_chat_tab_id: function(data) {
      return (
        data.name == "tab_id" &&
        data.new_val != core.websocket.tab_id &&
        core.nav.url.sec == "chat"
      );
    },

    check_new_connect_and_tab_ids: function(data) {
      return (
        data.name == "msg_appeal_connect" &&
        data.new_val == "true" &&
        core.websocket.tab_id != core.storage.get("tab_id")
      );
    },

    checkLockedOnline: () => {
      if (!msg.tab.locked && msg.tab.check_chat_connect_or_appeals()) {
        msg.tab.lock_modal();
      }
    },

    check_chat_connect_or_appeals: function() {
      return (
        core.storage.get(
          "msg_appeal_connect"
        ) /* ||
          core.storage.get("msg_appeal_count") > 0*/ &&
        core.nav.url.sec != "chat"
      );
    },

    lock_page: function() {
      if (msg.tab.locked) return false;

      msg.tab.locked = true;

      core.draw.favicon("/www/img/favicon_error.ico");

      core.nav.send(
        {
          tpls: {
            tab_lock_page: "window"
          },
          after: function(data) {
            core.draw.window({
              id: "msg_tab_lock_page",
              body: data.tpls.window,
              opacity: 1,
              hover: true,
              backgroundColor: "#FFF",
              close: {
                overlay: false,
                esc: false
              },
              callback: {
                afterOpen: function() {
                  chatDestroy();
                  msg.state.tab_active = false;
                  core.websocket.block = true;
                }
              }
            });
          }
        },
        false
      );
    },

    lock_modal: function() {
      if (msg.tab.locked) return false;

      msg.tab.locked = true;

      core.nav.send(
        {
          tpls: {
            tab_lock_modal: "window"
          },
          after: function(data) {
            core.draw.window({
              id: "msg_tab_lock_modal",
              body: data.tpls.window,
              close: {
                overlay: false,
                esc: false
              },
              callback: {
                afterOpen: function() {
                  msg.state.tab_active = false;
                  core.websocket.block = true;
                }
              }
            });
          }
        },
        false
      );
    },

    go_chat: function() {
      window.location = core.nav.getUrl({
        sol: "msg",
        sec: "chat",
        params: {
          project_id: core.nav.url.params.project_id
        }
      });
    }
  },

  preventFollow: function(el) {
    if (typeof msg.chat !== undefined) {
      if (msg.chat.appeal_connect) {
        core.informer.show("Отключите получение обращений", 1000);
        return false;
      }
    }

    return true;
  },

  /**
   * Открытие МО просмотра профиля
   */
  profile_editor: function() {
    import("../ReactFeatures/UsersProfileModal/UsersProfileViewModal").then(
      (Module) => {
        const UsersProfileViewModal = Module.default;
        const modalId = "usersProfileViewModal";
        const close = () =>
          $(`#reactRoot_${modalId}`)
            .parent()
            .modal("close");
        msg.renderModalForReact(modalId, 592, (root) => {
          ReactDom.render(
            <ThemeProvider store={store}>
              <Provider store={store}>
                <UsersProfileViewModal profileId={core.user.id} close={close} />
              </Provider>
            </ThemeProvider>,
            root
          );
        });
      }
    );
  },

  message_photo: function(el) {
    core.tools.loadCode("photoswipe.min.js", function() {
      core.tools.loadCode("photoswipe-ui-default.min.js", function() {
        var pswpElement = document.querySelectorAll(".pswp")[0],
          items = [
            {
              src: el.data("src"),
              w: el.data("width"),
              h: el.data("height")
            }
          ];

        var options = {
          history: false,
          focus: false,
          showAnimationDuration: 0,
          hideAnimationDuration: 0,
          shareEl: false,
          fullscreenEl: false,
          zoomEl: false
        };

        var gallery = new PhotoSwipe(
          pswpElement,
          PhotoSwipeUI_Default,
          items,
          options
        );
        gallery.init();
      });
    });
  },

  dragndrop: {
    src: undefined,
    src_index: undefined,
    dst_index: undefined,

    init: function() {
      core.tools.loadCode("dragndrop.js", function() {
        var el = $(".statsAgentSettings_list").find(
          ".statsAgentSettings_item_move"
        );
        el.dragdrop({
          makeClone: true,
          sourceHide: true,
          dragClass: "drag",
          canDrag: function(el, event) {
            msg.dragndrop.src = el.closest(".statsAgentSettings_itemWrap");
            msg.dragndrop.src_index = msg.dragndrop.src.index();
            msg.dragndrop.dst_index = msg.dragndrop.src_index;

            return msg.dragndrop.src;
          },
          canDrop: function(dst) {
            if (dst.parent().hasClass("statsAgentSettings_list")) {
              msg.dragndrop.dst_index = dst.index();

              if (msg.dragndrop.src_index < msg.dragndrop.dst_index)
                msg.dragndrop.src.insertAfter(dst);
              else msg.dragndrop.src.insertBefore(dst);
            }

            return false;
          }
        });
        el.bind(
          "mousedown.dragdrop touchstart.dragdrop",
          msg.dragndrop.onStart
        );
      });
    },
    destroy: function() {
      var el = $(".statsAgentSettings_list").find(
        ".statsAgentSettings_item_move"
      );
      el.unbind("mousedown.dragdrop touchstart.dragdrop");
      return el;
    }
  },

  agent_set: {
    modal: {},

    init: function(el, agent_id) {
      if (el.hasClass("load")) return false;

      el.addClass("load");
      $.ajax({
        url: "/msg?sec=stats",
        data: {
          project_id: core.nav.url.params.project_id || 0,
          agent_id: agent_id || 0,
          tpls: {
            stats_agent_settings: "window"
          }
        },
        dataType: "JSON",
        type: "GET",
        success: function(r) {
          core.draw.window({
            id: "msg_statsAgentSettings",
            body: r.tpls.window,
            callback: {
              beforeOpen: function() {
                el.closest(".stats_agent_wrap").addClass("set");
              },
              afterOpen: function(data) {
                msg.agent_set.modal.body = data.body;
                msg.agent_set.modal.skill_list = msg.agent_set.modal.body.find(
                  ".statsAgentSettings_list"
                );

                msg.agent_set.skill_check();
                msg.dragndrop.init();
              },
              beforeClose: function() {
                msg.dragndrop.destroy();
              },
              afterClose: function() {
                el.closest(".stats_agent_wrap").removeClass("set");
              }
            }
          });
          el.removeClass("load");
        }
      });
    },

    skill_check: function() {
      const selects = [];
      const items = $(".statsAgentSettings_list").find("select");

      $.each(items, function(i, el) {
        const elem = $(el);
        const skillId = elem.attr("data-skill-id");
        if (skillId && !msg.agent_set.skill_obj[skillId]) {
          elem.attr("disabled", "disabled");
        } else {
          selects.push(elem.val());
        }
      });

      if (selects.length === Object.keys(msg.agent_set.skill_obj).length) {
        $(".statsAgentSettings_add").addClass("hidden");
      } else {
        $(".statsAgentSettings_add").removeClass("hidden");
      }

      $.each(items, function(i, el) {
        var cur_val = $(el).val(),
          options = $(el)
            .find("option")
            .show();

        $.each(options, function(i, option) {
          var value = $(option).attr("value");
          if (
            cur_val !== value &&
            value != 0 &&
            $.inArray(value, selects) > -1
          ) {
            $(option).hide();
          }
        });
      });
    },

    skill_change: function(el) {
      if (el.val() !== 0) {
        if (
          $(".statsAgentSettings_list").find("select").length !==
          Object.keys(msg.agent_set.skill_obj).length
        )
          $(".statsAgentSettings_add").removeClass("hidden");
      }

      var item = $(".statsAgentSettings_list").find(
        ".statsAgentSettings_itemWrap"
      );

      if (!$("#msg_categoryEditor")) {
        if (
          (item.length == 1 && item.find("select").val() == 0) ||
          el.find("option:not([style])").length < 3
        ) {
          $(".statsAgentSettings_add").addClass("hidden");
          el.parents(".statsAgentSettings_list")
            .parent()
            .find(".wsForm_checkbox")
            .addClass("hidden");
        } else {
          $(".statsAgentSettings_add").removeClass("hidden");
        }
      }

      msg.agent_set.skill_check();
    },

    skill_sort: function() {
      var sortable = [],
        arr = msg.agent_set.skill_obj;
      if (!arr.sort)
        for (var val in arr) {
          sortable.push([val, arr[val]]);
        }
      else sortable = arr;

      sortable.sort(function(a, b) {
        var valA = a[1].toLowerCase(),
          valB = b[1].toLowerCase();
        return valA < valB ? -1 : valA > valB ? 1 : 0;
      });
      msg.agent_set.skill_obj = Object.fromEntries(sortable);
    },

    skill_obj: {},

    skill_add: function(flag) {
      msg.agent_set.skill_sort();

      var item = "";

      if (flag != "filter") {
        $(".statsAgentSettings_add").addClass("hidden");
        item += '<option value="0">Не выбрана</option>';

        $.each(Object.entries(msg.agent_set.skill_obj), function(i, skill) {
          item += '<option value="' + skill[0] + '">' + skill[1] + "</option>";
        });

        var buffer =
          '<div class="statsAgentSettings_itemWrap wsForm_field">\
                        <div class="statsAgentSettings_item">\
                        <div class="statsAgentSettings_item_move"></div>\
                            <div class="statsAgentSettings_item_delete" onclick="msg.agent_set.skill_remove($(this));"></div>\
                            <div class="wsForm_select">\
                                <select name="skill_id[]" onfocus="core.form.itemFocus($(this));" onblur="core.form.itemBlur($(this));" onchange="core.form.selectChange($(this));msg.agent_set.skill_check();msg.agent_set.skill_change($(this));">\
                                ' +
          item +
          '\
                                </select>\
                            </div>\
                        </div>\
                        <div class="wsForm_fieldError"></div>\
                </div>';

        $(".statsAgentSettings_list").append(buffer);
      }

      msg.agent_set.skill_check();

      msg.dragndrop.destroy();
      msg.dragndrop.init();
    },

    skill_remove: function(el) {
      const skillId = el.attr("data-skill-id");
      if (skillId && !msg.agent_set.skill_obj[skillId]) return;

      if (el.parents(".statsAgentSettings_list").parent()[0]) {
        el.parents(".statsAgentSettings_list")
          .parent()
          .find(".wsForm_checkbox.hidden")
          .removeClass("hidden");

        var items = $(".statsAgentSettings_list").find("select"),
          cur_val = el
            .parent()
            .find("select")
            .val();

        el.parents(".statsAgentSettings_list")
          .find('option[value="' + cur_val + '"]')
          .show();
      }

      if (
        $(".statsAgentSettings_list").find(".statsAgentSettings_itemWrap")
          .length > 1
      ) {
        var wrap = el.closest(".statsAgentSettings_itemWrap");
        if (wrap.find(".statsAgentSettings_item_move")[0]) {
          wrap.find(".statsAgentSettings_item_move").dragdrop("destroy");
        }
        wrap.remove();

        if (
          $(".statsAgentSettings_list").find("select").length !==
          Object.keys(msg.agent_set.skill_obj).length
        ) {
          $(".statsAgentSettings_add").removeClass("hidden");
        }
      }

      if (core.nav.url.sec == "settings") {
        if (
          msg.settings.groups.userEditor_modal
            .find("form")
            .hasClass("agentEdition")
        ) {
          msg.agent_set.skill_check();
        } else if (
          msg.settings.groups.userEditor_modal
            .find("form")
            .hasClass("supervisorEdition")
        ) {
          if (
            msg.settings.groups.userEditor_modal.skill_list.find(
              ".statsAgentSettings_itemWrap"
            ).length == 0
          ) {
            msg.settings.groups.userEditor_modal
              .find("form")
              .removeClass("groupsList");
            msg.settings.groups.userEditor_modal.skill_check.prop(
              "checked",
              true
            );
            if (!$(".statsAgentSettings_list").find("select").length)
              msg.settings.groups.userEditor_modal
                .find(".statsAgentSettings_add")
                .addClass("hidden");
          }
        }
      }
    },

    submit: function(form) {
      const disabledSelects = $(".statsAgentSettings_list").find(
        "select:disabled"
      );
      $.each(disabledSelects, function(i, el) {
        $(el).removeAttr("disabled");
      });

      $.ajax({
        url: "/msg?sec=save_user_skill",
        data: form.serializeArray(),
        dataType: "JSON",
        type: "GET",
        success: function(r) {
          $.each(disabledSelects, function(i, el) {
            $(el).attr("disabled", "disabled");
          });

          r.errors.skill_id = r.errors.skill_id
            .reduce(function(prev, cur) {
              return prev.indexOf(cur) < 0 ? prev.concat([cur]) : prev;
            }, [])
            .join("");
          if (core.form.check(form, "statsAgentSettings", r.errors)) {
            $("#msg_statsAgentSettings").modal("close");
            var list = $(".stats_agentsWrap_content").find(
              ".stats_agents_list"
            );
          } else {
            var selects = $(".statsAgentSettings_list").find("select");
            $.each(selects, function(i, item) {
              if ($(item).val() == 0) {
                $(item)
                  .closest(".wsForm_field")
                  .addClass("fail");
              }
            });
            core.informer.show(msg.lng.stats.appealError, 1500);
            core.informer.el.css({
              left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
            });
          }
        }
      });
    }
  },

  selection: {
    input_range: {},

    set: function(el) {
      this.input_range = {};

      el.focus();

      var objSel = window.getSelection();

      if (objSel.getRangeAt && objSel.rangeCount) {
        var range = objSel.getRangeAt(0);
        this.input_range = range.cloneRange();
      } else {
        this.input_range = document.createRange();
      }
    },

    get: function() {
      return this.input_range.cloneRange();
    },

    print: function(el, text) {
      var sel = window.getSelection(),
        range;

      el.focus();

      if (el.tagName == "TEXTAREA") {
        range = document.createRange();
        range.text = text;

        var p = el.selectionStart,
          str = $(el).val();

        if (p != undefined) $(el).val(str.slice(0, p) + text + str.slice(p));
        else {
          range = document.createRange();
          range.text = $(this).val();
        }
        var caret_position = p + text.length;
        if (el.setSelectionRange)
          el.setSelectionRange(caret_position, caret_position);
      } else if (sel.getRangeAt && sel.rangeCount && this.input_range) {
        range = this.get();
        range.deleteContents();
        var _el = document.createElement("div");
        _el.innerHTML = text;

        var frag = document.createDocumentFragment(),
          node,
          lastNode;

        while ((node = _el.firstChild)) {
          lastNode = frag.appendChild(node);
        }
        var firstNode = frag.firstChild;
        range.insertNode(firstNode);

        if (lastNode) {
          range = range.cloneRange();
          range.setStartAfter(firstNode);
          sel.removeAllRanges();
          sel.addRange(range);
        }

        this.set(el);
      }
    }
  },

  pagination: {
    els: {},

    init: function(options) {
      const wsScroll_content = options.el.find(".wsScroll_content");
      if (options.el && !msg.pagination.els[wsScroll_content.attr("class")]) {
        msg.pagination.els[wsScroll_content.attr("class")] = {
          block: options.el,
          tpl: options.tpl,
          search: options.search,
          step: wsScroll_content.scrollTop(),
          page: 0,
          stop: false,
          loading: false
        };
        wsScroll_content.on({
          scroll: msg.pagination.load
        });
      }
    },

    destroy: function() {
      if (Object.keys(msg.pagination.els).length) {
        msg.pagination.els = {};
        $(".wsScroll_content").off("scroll");
      }
    },

    resize: function() {
      msg.pagination.step = msg.pagination.block.height() - core.user.winHeight;
      core.tools.loadCode("scroll.js", function() {
        msg.pagination.block.nanoScroller({
          flash: !0,
          alwaysVisible: true
        });
      });
    },

    reset: function(el) {
      var pblock =
        msg.pagination.els[el.find(".wsScroll_content").attr("class")];
      if (!pblock) {
        return;
      }

      if (pblock.block) {
        pblock.step = pblock.block.height() - core.user.winHeight;
      }
      pblock.page = 0;
      pblock.loading = false;
      pblock.stop = false;
    },

    load: function(el) {
      var pblock = msg.pagination.els[$(el.target).attr("class")];
      if (pblock.loading || pblock.stop) return false;

      var scr = pblock.block.find(".wsScroll_content").scrollTop();

      if (scr >= pblock.step) {
        pblock.loading = true;
        var buffer = '<div class="pagination_load"></div>';
        pblock.block.find(".wsScroll_content>div").append(buffer);

        var data = {
          page: pblock.page + 1,
          tpls: {},
          search: pblock.search ? pblock.search.val() : 0
        };
        data.tpls[pblock.tpl] = "block";

        $.ajax({
          url: core.nav.getUrl(),
          data: data,
          type: "GET",
          cache: false,
          dataType: "JSON",
          success: function(r) {
            if (!$.trim(r.tpls.block).length) pblock.stop = true;

            pblock.page++;

            pblock.block.find(".pagination_load").replaceWith(r.tpls.block);
            pblock.step =
              pblock.block.find(".wsScroll_content>div").height() -
              core.user.winHeight;
            pblock.loading = false;

            if (pblock.callback) pblock.callback();
          }
        });
      }
    }
  },

  form_fields: {
    add_field: function(el) {
      var new_el = el
        .siblings(".statsAgentSettings_list")
        .children("DIV")
        .first()
        .clone();
      new_el
        .find("input")
        .val("")
        .end()
        .find(".fail")
        .removeClass("fail");
      el.siblings(".statsAgentSettings_list").append(new_el);
    },

    remove_field: function(el) {
      if (el.parents(".statsAgentSettings_list").children("DIV").length > 1)
        el.parents(".statsAgentSettings_itemWrap").remove();
      else {
        var parent = el.parents(".logic_field_group");
        msg.form_fields.clear_field(el);
        parent
          .find(".wsForm_checkbox input")
          .trigger("click")
          .end()
          .find(".fail")
          .removeClass("fail");
      }
    },

    clone_field: function(block) {
      var new_el = block
        .children("div")
        .first()
        .clone();
      new_el
        .find("input")
        .val("")
        .end()
        .parents(".fail")
        .removeClass("fail");

      return new_el;
    },

    clear_field: function(el) {
      var clear_input = msg.form_fields.clone_field(
        el.parents(".logic_field_group").find(".statsAgentSettings_list")
      );
      if (clear_input[0].tagName == "DIV")
        el.parents(".logic_field_group")
          .find(".statsAgentSettings_list")
          .html(clear_input[0]);
    },

    color_field: {
      init: function(form) {
        var color_fields = form.find("[type=color]");
        color_fields.each(function(key, elem) {
          elem = $(elem);
          msg.form_fields.color_field.change_label(elem);
          msg.form_fields.color_field.add_control_listener(elem);
          msg.form_fields.color_field.add_label_listener(
            elem.parent().find(".wsForm_colorHex_value")
          );
          msg.form_fields.color_field.bridge_click_to_colorpick(
            elem.siblings("span")
          );
          msg.form_fields.color_field.change_span_bg(elem);
        });
      },

      bridge_click_to_colorpick: function(el) {
        el.on("click", function() {
          $(this)
            .siblings("input")
            .trigger("click");
        });
      },

      change_label: function(el) {
        el.parent().find(".wsForm_colorHex_value")[0].value = el.val();
      },

      change_control: function(el) {
        el
          .parent()
          .parent()
          .find(".wsForm_colorControl_value")[0].value = el.val();
      },

      change_span_bg: function(el) {
        el.parents(".wsForm_color")
          .find("span")
          .attr("style", "background:" + el.val());
      },

      add_label_listener: function(el) {
        el.on("input", this.handlers.on_input_label);
        el.on("blur", this.handlers.on_blur_label);
      },

      add_control_listener: function(el) {
        el.on("change", this.handlers.on_change_control);
      },

      remove_control_listener: function(el) {
        el.off("change", this.handlers.on_change_control);
      },

      remove_label_listener: function(el) {
        el.off("input", this.handlers.on_input_label);
        el.off("blur", this.handlers.on_blur_label);
      },

      destroy: function(form) {
        var color_fields = form.find("[type=color]");
        color_fields.each(function(key, elem) {
          elem = $(elem);
          msg.form_fields.color_field.remove_control_listener(elem);
          msg.form_fields.color_field.remove_label_listener(
            elem.parent().find(".wsForm_colorHex_value")
          );
        });
      },

      handlers: {
        on_change_control: function(event) {
          var e = event || window.event;
          var target = e.target || e.srcElement;
          target = $(target);
          msg.form_fields.color_field.change_label(target);
          msg.form_fields.color_field.change_span_bg(target);
        },

        on_input_label: function(event) {
          var e = event || window.event;
          var target = e.target || e.srcElement;
          target = $(target);
          msg.form_fields.color_field.change_control(target);
          msg.form_fields.color_field.change_span_bg(target);
        },

        on_blur_label: function(event) {
          var e = event || window.event;
          var target = e.target || e.srcElement;
          target = $(target);
          if (
            target.val() !=
            target
              .parent()
              .parent()
              .find(".wsForm_colorControl_value")[0].value
          ) {
            target.val(
              target
                .parent()
                .parent()
                .find(".wsForm_colorControl_value")[0].value
            );
            msg.form_fields.color_field.change_span_bg(target);
          }
        }
      }
    },

    date_fields: {
      nameInputStart: "date_start",
      nameInputEnd: "date_end",
      selectorInputStart: 'input[name="date_start"]',
      selectorInputEnd: 'input[name="date_end"]',

      /**
       * Вывести информер
       * @param {String} message текст сообщения
       */
      showMessage: function(message) {
        if (!message) {
          return;
        }
        core.informer.show(message, 3000);
      },

      /**
       * Получение даты из объекта с активными элементами периода
       * @param {Object<Node>} cachedElements
       * @returns {Object<String>}
       */
      getDatePeriod: function(cachedElements) {
        var result = false;
        if (typeof cachedElements === "object") {
          result = {};
          result.from = cachedElements.elFrom.val();
          result.to = cachedElements.elTo.val();
        }
        return this.stringToDate(result);
      },

      /**
       * Получение активных элементов начала и окончания периода
       * Если объект не передан попытка найти поля в DOM
       * @param {Node=} el
       * @returns {Object<Node>}
       */
      getActiveElements: function(el) {
        var result, dateStartEl, dateEndEl;
        if (el !== undefined) {
          var elName = el.attr("name");
          if (elName !== this.nameInputStart && elName !== this.nameInputEnd) {
            return false;
          }
          var parent = el.parent().parent();
          dateStartEl = parent.find(this.selectorInputStart);
          dateEndEl = parent.find(this.selectorInputEnd);
        } else {
          (dateStartEl = $(this.selectorInputStart)),
            (dateEndEl = $(this.selectorInputEnd));
        }
        if (
          typeof dateStartEl === "object" &&
          typeof dateStartEl.val === "function" &&
          typeof dateEndEl === "object" &&
          typeof dateEndEl.val === "function"
        ) {
          result = { elFrom: dateStartEl, elTo: dateEndEl };
        }
        return result;
      },

      /**
       * Проверка периода на размер не больше квартала (3 месяца)
       * расчет ведется от конца периода отнимается 3 месяца и из этого вычитается начальное значение периода
       * тем самым узнается разница между эталонным квартальным периодом (от конечной даты) и переданным в параметре
       * Если разница больше 0, это означает, что задан период больше квартала.
       * @param {Object<Date>} period проверяемый период
       * @param {Date} period.from
       * @param {Date} period.to
       * @returns {Boolean} true - входит в проверяемый период; false - превышает период;
       */
      isInQuartalPeriod: function(period) {
        var correctQuartalsDate = msg.addMonths(period.to, -3),
          daysDiff = (correctQuartalsDate - period.from) / 1000 / 60 / 60 / 24;
        return daysDiff > 0 ? false : true;
      },
      /**
       * Проверка периода на размер не больше недели
       * @param {Object<Date>} period проверяемый период
       * @param {Date} period.from
       * @param {Date} period.to
       * @returns {Boolean} true - входит в проверяемый период; false - превышает период;
       */
      isInWeekPeriod: function(period) {
        return period.to - period.from <= 518400000;
      },
      /**
       * Перевод дат периода из String в Date
       * @param {Object<String>} period
       * @param {String} period.from
       * @param {String} period.to
       * @returns {Object<Date>}
       */
      stringToDate: function(period) {
        if (!period.from || !period.to) {
          return false;
        }
        var dateStart, dateEnd;
        dateStart = period.from.split(".");
        dateEnd = period.to.split(".");
        period.from = new Date(dateStart[2], dateStart[1] - 1, dateStart[0]);
        period.to = new Date(dateEnd[2], dateEnd[1] - 1, dateEnd[0]);
        return period;
      },

      /**
       * Главный метод проверки
       * @param {Node=} el input с датой в строковом виде
       * @returns {Boolean} изменялось (true) ли значение при валидации или нет (false)
       */
      validatation: function(el) {
        var cachedEls = this.getActiveElements(el),
          datePeriod = this.getDatePeriod(cachedEls);

        if (datePeriod === false) return false;
        if (!cachedEls) return false;

        var textMessage,
          today = new Date(),
          MAX_DAYS_PERIOD = 6;

        // Установленная дата больше сегодняшней даты ?
        if (datePeriod.from > today) {
          cachedEls.elFrom.val(msg.formatDate(today));
          datePeriod.from = today;
          textMessage = msg.lng.cantSeeFuture;
        }
        if (datePeriod.to > today) {
          cachedEls.elTo.val(msg.formatDate(today));
          datePeriod.to = today;
          textMessage = msg.lng.cantSeeFuture;
        }

        if (!el || el.attr("name") === this.nameInputEnd) {
          if (!this.isInWeekPeriod(datePeriod)) {
            let validDate = msg.addDays(datePeriod.from, MAX_DAYS_PERIOD);
            datePeriod.to = validDate;
            cachedEls.elTo.val(msg.formatDate(validDate));
            textMessage = msg.lng.periodMoreThenWeek;
          }
          // Дата начала больше Даты конца ?
          else if (datePeriod.from > datePeriod.to) {
            datePeriod.to = datePeriod.from;
            cachedEls.elTo.val(msg.formatDate(datePeriod.from));
            textMessage = msg.lng.startDateIsLessEndDate;
          }
          // Если меняется начало периода, сдвигаем и окончание
        } else {
          let validDate = msg.addDays(datePeriod.from, MAX_DAYS_PERIOD);
          datePeriod.to = validDate;
          cachedEls.elTo.val(msg.formatDate(validDate));
        }

        if (textMessage) {
          this.showMessage(textMessage);
          return true;
        }
        return false;
      }
    },

    phone_fields: {
      mask: "+7 (000) 000-00-00",

      errorMessage: "",

      init: function(el) {
        el.mask(this.mask);
        el.bind("change", { callback: el[0].onchange }, this.handlers.onchange);
        el[0].onchange = undefined;
        el.bind("focus", this.handlers.onfocus);
        el.bind("keypress", this.handlers.onkeypress);
      },

      checkIsPhone: function(value) {
        return value.match(/^$|^\d{10}$/);
      },

      checkHasOnlyMask: function(cleanVal, val) {
        return cleanVal === "" && val !== "";
      },

      saveLastValueInData: function(el) {
        el.data("lastValue", el.val());
      },

      getAndRemoveLastValueFromData: function(el) {
        if (typeof el.data().lastValue === "string") {
          el.val(el.data().lastValue);
          el.removeData("lastValue");
        } else {
          el.val("");
        }
      },

      handlers: {
        onchange: function(e) {
          if (
            msg.form_fields.phone_fields.checkHasOnlyMask(
              $(this).cleanVal(),
              $(this).val()
            ) ||
            !msg.form_fields.phone_fields.checkIsPhone($(this).cleanVal())
          ) {
            core.informer.show(msg.form_fields.phone_fields.errorMessage, 3000);
            msg.form_fields.phone_fields.getAndRemoveLastValueFromData($(this));
            return;
          }
          e.data.callback.call(e.target);
        },

        onfocus: function() {
          msg.form_fields.phone_fields.saveLastValueInData($(this));
        },

        onkeypress: function(e) {
          msg.form_fields.blurOnEnter($(this), e);
        }
      }
    },

    /**
     * Показывает/скрывает следующее поле при выборе/не выборе чекбокса
     * @param el {object} jQuery элемент чекбокс
     * @param isInverted {boolean} логика работы.
     * Если true, то выбор чекбокса скрывает поле, если false - наоборот показывает
     * @return {void}
     */
    toggle_field_by_checkbox: function(el, isInverted) {
      if (el.is(":checked")) {
        if (isInverted) {
          el.closest("div.wsForm_field.related").addClass("sel");
        } else {
          el.closest("div.wsForm_field.related").removeClass("sel");
        }
      } else {
        if (isInverted) {
          el.closest("div.wsForm_field.related").removeClass("sel");
        } else {
          el.closest("div.wsForm_field.related").addClass("sel");
        }
      }
    },
    /**
     * Показывает/скрывает следующее поле при выборе радиобаттона
     * предполагается, что радиобаттонов 2 - на показ и скрытие
     * @param el {object} jQuery элемент радиобаттона
     * @param state {boolean} новое состояние.
     * Если true, то скрывает поле, если false - наоборот показывает
     * @return {void}
     */
    toggle_field_by_radiobtn: function(el, state) {
      if (!el) return;
      if (state) {
        el.closest("div.wsForm_field.related").addClass("sel");
      } else {
        el.closest("div.wsForm_field.related").removeClass("sel");
      }
    },

    blurOnEnter: function(el, event) {
      var keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode === 13) {
        el.blur();
      }
    }
  },

  copy_to_clipboard: function(el, wrapElName) {
    var hiddenEl = document.createElement("div"),
      wrapper = document.createElement(wrapElName),
      content = el.textContent.trim();

    hiddenEl.style.position = "absolute";
    hiddenEl.style.left = "-9999px";
    hiddenEl.appendChild(wrapper);
    document.body.appendChild(hiddenEl);

    if (wrapper.nodeName.toLowerCase() === "textarea") {
      wrapper.value = content;
      document.getSelection().selectAllChildren(hiddenEl);
      wrapper.focus();
      wrapper.select();
    } else {
      $(hiddenEl).append(el.textContent.replace(/[^A-Z0-9]/gi, ""));
      window.getSelection().selectAllChildren(hiddenEl);
    }

    document.execCommand("copy", false);
    $(hiddenEl).remove();
    core.informer.show(msg.lng.settings.copyToClipboardSuccess, 1500);
    core.informer.el.css({
      left: core.user.winWidth / 2 - core.informer.el.outerWidth() / 2
    });
  },

  fancyChoice: {
    show: function(event, el) {
      core.tools.cancelBubble(event);
      var target = $(event.target);
      if (
        target.hasClass("wsForm_fancyChoice_tag") ||
        target.hasClass("wsForm_fancyChoice_item")
      ) {
        return;
      }
      if (!msg.fancyChoice.list.length) {
        el.find(
          ".wsForm_fancyChoice_hidden_list .wsForm_fancyChoice_item"
        ).each(function(index, item) {
          msg.fancyChoice.list.push($(item).clone());
        });
      }
      el.find(".wsForm_fancyChoice_textarea")
        .focus()
        .parent()
        .addClass("focused");
      el.find(".wsForm_fancyChoice_list").show();
    },

    hide: function(event) {
      var target = $(event.target);

      if (!target.hasClass("wsModal_close")) {
        core.tools.cancelBubble(event);
      }

      if (
        target.closest(".wsForm_fancyChoice").length ||
        $(".wsForm_fancyChoice_list").css("display") === "none"
      ) {
        return;
      }

      $(".wsForm_fancyChoice_textarea").text("");
      $(".wsForm_fancyChoice_list").hide();
      $(".wsForm_fancyChoice_container").removeClass("focused");

      if (msg.fancyChoice.rest.length) {
        $(".wsForm_fancyChoice_scroll").html(msg.fancyChoice.rest);
      } else {
        $(".wsForm_fancyChoice_scroll").html(msg.fancyChoice.list);
      }
    },

    search: function(el) {
      var needle = el
          .text()
          .toLowerCase()
          .trim(),
        matches = [],
        list = msg.fancyChoice.rest.length
          ? msg.fancyChoice.rest
          : msg.fancyChoice.list;

      list.forEach(function(item) {
        if (
          ~$(item)
            .text()
            .toLowerCase()
            .trim()
            .indexOf(needle)
        )
          matches.push($(item).clone());
      });

      if (matches.length) {
        el.parent()
          .find(".wsForm_fancyChoice_scroll")
          .html(matches);
      } else {
        el.parent()
          .find(".wsForm_fancyChoice_scroll")
          .html(
            $("<div>")
              .addClass("wsForm_fancyChoice_item")
              .text(msg.lng.stats.appealsExportGroupsNotFound)
          );
      }
    },

    list: [],

    rest: [],

    add: function(id, name) {
      if (!id || !name) return;
      msg.fancyChoice.rest = [];

      var el = $("<div>")
          .addClass("wsForm_fancyChoice_tag")
          .attr(
            "onclick",
            "msg.fancyChoice.remove($(this), " + id + ", '" + name + "');"
          )
          .text(name),
        added = [];

      $(".wsForm_fancyChoice_tag").each(function(index, element) {
        added.push(
          $(element)
            .text()
            .toLowerCase()
            .trim()
        );
      });

      msg.fancyChoice.list.forEach(function(item) {
        if (
          $(item)
            .text()
            .toLowerCase()
            .trim() !== name.toLowerCase().trim()
        )
          msg.fancyChoice.rest.push($(item).clone());
      });

      msg.fancyChoice.rest = msg.fancyChoice.rest.filter(function(item) {
        if (
          !~added.indexOf(
            $(item)
              .text()
              .toLowerCase()
              .trim()
          )
        )
          return true;
        return false;
      });

      if (!msg.fancyChoice.rest.length) {
        msg.fancyChoice.rest.push(
          $("<div>")
            .addClass("wsForm_fancyChoice_item")
            .text(msg.lng.stats.appealsExportGroupsAddedAll)
        );
      }

      $(".wsForm_fancyChoice_scroll").html(msg.fancyChoice.rest);
      el.insertBefore($(".wsForm_fancyChoice_textarea"));
      $("#reportSettings_form").prepend(
        $("<input>").attr({
          type: "hidden",
          name: "skill_id[]",
          value: id
        })
      );
      $(".wsForm_fancyChoice_textarea").focus();
    },

    remove: function(el, id, name) {
      if (!el || !id || !name) return;

      msg.fancyChoice.list.forEach(function(item) {
        if (
          $(item)
            .text()
            .toLowerCase()
            .trim() === name.toLowerCase().trim()
        )
          msg.fancyChoice.rest.push($(item).clone());
      });

      msg.fancyChoice.rest = msg.fancyChoice.rest.filter(function(item) {
        if ($(item).text() === msg.lng.stats.appealsExportGroupsAddedAll)
          return false;
        return true;
      });

      $(".wsForm_fancyChoice_scroll").html(msg.fancyChoice.rest);
      el.remove();
      $("#reportSettings_form")
        .find("input[name='skill_id[]'][value=" + id + "]")
        .remove();
      $(".wsForm_fancyChoice_textarea").click();
    }
  },

  formatDate: function(date) {
    return (
      ("0" + date.getDate()).slice(-2) +
      "." +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "." +
      date.getFullYear()
    );
  },

  /**
   * Метод для получения количества дней в месяце
   * @param {Date} date дата
   * @returns {Number}
   */
  getDaysInMonth: function(date) {
    // if you'll set non-existent day (in our case = 33) to Date obj, you'll get the corresponding day of the next month (33.01 -> returns 2, so daysInMonth would be 33 - 2 = 31)
    return 33 - new Date(date.getFullYear(), date.getMonth(), 33).getDate();
  },

  /**
   * Метод добавляющий/отнимающий месяцы к/от переданной даты
   * @param {Date} date дата для редактирвоания
   * @param {Number} value количество месяцев. Если значение отрицательное, метод будет отнимать месяцы!
   * @returns {Date} преобразованная дата
   */
  addMonths: function(date, value) {
    var d = new Date(date);
    var n = date.getDate();
    var daysInCurrentMonth = msg.getDaysInMonth(date);

    d.setDate(1);
    d.setMonth(d.getMonth() + value);

    var daysInUpdatedMonth = msg.getDaysInMonth(d);

    // assure that 28.02.2018 plus 1 month will be 31.03.2018
    var changeDay =
      daysInUpdatedMonth !== daysInCurrentMonth ? daysInCurrentMonth - n : null;
    changeDay = changeDay > n ? null : changeDay;

    var dateToSet =
      changeDay !== null
        ? msg.getDaysInMonth(d) - changeDay
        : Math.min(n, msg.getDaysInMonth(d));
    d.setDate(dateToSet);
    return d;
  },

  addDays: function(date, value) {
    let d = new Date(date),
      today = new Date();
    d.setDate(d.getDate() + value);
    if (d > today) {
      d = today;
    }
    return d;
  },

  closeCurrentModal: function() {
    $(".modal-container").modal("close");
  },

  sendLogout: function() {
    store.dispatch(sendUserlogoff());
  },

  goOffline: function() {
    const postAction = () => {
      msg.tab.locked = false;
      msg.state.appeal_connect = false;
      msg.state.appeal_count = 0;
      core.storage.set("msg_appeal_connect", false);
      core.storage.set("msg_appeal_count", 0);
      msg.closeCurrentModal();
    };
    const onError = () => {
      // временное решение для смены урла компонентом фильтра Дашборда
      // необходимо для корректной работы вкладки статистики
      msg.closeCurrentModal();
    };
    const action = core.storage.get("msg_appeal_connect")
      ? sendUserOffline({
          transfer: true,
          substatusId: 2,
          cb: postAction,
          cbError: onError
        })
      : transferAllAppeals({ postAction, onError });

    store.dispatch(action);
  },

  /**
   * TODO: Должно лежать в core.js
   * @param {*} componentId
   * @param {*} callback
   */
  renderComponentForReact: function(componentId, callback) {
    let reactContainer = document.getElementById(`reactRoot_${componentId}`);
    reactContainer && callback(reactContainer);
  },

  renderModalForReact: function(
    modalId,
    modalWidth,
    callback,
    callbackClose = () => {}
  ) {
    let reactElement;

    let width = "auto";
    if (typeof modalWidth === "number" && modalWidth > 0) {
      width = `${modalWidth}px`;
    }
    core.draw.window({
      id: modalId,
      body: `
        <div class="wsModal" style="width: ${width}">
          <div id="reactRoot_${modalId}" class="reactRootModal"></div>
        </div>
        `,
      callback: {
        afterOpen() {
          reactElement = document.getElementById(`reactRoot_${modalId}`);
          $(".modal-container_i2").off();
          callback(reactElement);
        },
        afterClose() {
          callbackClose();
          ReactDom.unmountComponentAtNode(reactElement);
        }
      }
    });
  }
};
