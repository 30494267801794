import React, { useState } from "react";

import { DragAndDropSelect, FormField, Button } from "@omnichat/arm_ui_kit";
import { Input, Checkbox } from "../../../../Components/";

import * as s from "./CategoryModal.module.scss";

/**
 * Модель данных
 */
export interface ICategoryModalProps {
  id: null | number;
  all: boolean;
  onChangeAll: () => void;
  name: string;
  onChangeName: (name: string) => void;
  selectedGroups: number[];
  onChangeGroup: (groups: number[]) => void;
  skillGroups: SkillGroup[];
  onSave: () => void;
  onRemove: () => void;
}

interface SkillGroup {
  value: number;
  label: string;
}

/**
 * Модальное окно создания категорий шаблонов ответа.
 */
const CategoryModal = ({
  all,
  id,
  onChangeAll,
  name,
  onChangeName,
  skillGroups = [],
  selectedGroups,
  onChangeGroup,
  onSave,
  onRemove
}: ICategoryModalProps): JSX.Element => {
  const [nameError, setNameError] = useState(false);
  const [groupError, setGroupError] = useState(false);
  const headText = id ? "Редактирование категории" : "Новая категория";

  const validate = () => {
    if (!name) setNameError(true);

    if (!all && !selectedGroups.length) setGroupError(true);

    if (!name || (!all && !selectedGroups.length)) return;

    onSave();
  };

  const changeName = (value: string) => {
    setNameError(false);
    onChangeName(value);
  };

  const changeGroup = groups => {
    setGroupError(false);
    onChangeGroup(groups);
  };

  return (
    <div className={s["modal"]}>
      <header className={s["header"]}>{headText}</header>
      <div className={s["modalItem"]}>
        <Input
          label="Название"
          value={name}
          placeholder="Введите название шаблона"
          required={true}
          isError={nameError}
          actionText={nameError ? "Укажите название" : ""}
          onChange={value => changeName(value)}
        />
      </div>
      <div className={s["modalItem"]}>
        <FormField label="Группа" required={true}>
          <>
            <div className={s["groupsContainer"]}>
              <Checkbox
                caption="Все группы"
                checked={all}
                onChange={onChangeAll}
              />
              <p>Шаблоны данной категории доступны сотрудникам всех групп</p>
            </div>
            {!all && (
              <DragAndDropSelect
                addText="Добавить группу"
                required={true}
                isError={groupError}
                actionText={
                  groupError ? "Должна быть указана хотя бы одна группа" : ""
                }
                selected={selectedGroups}
                options={skillGroups}
                onChange={selected => changeGroup(selected)}
              />
            )}
          </>
        </FormField>
      </div>
      <div className={s["modalButtons"]}>
        <Button
          type="default"
          theme={"red"}
          onClick={() => onRemove()}
          text="Удалить"
        />
        <Button
          type="default"
          theme={"green"}
          onClick={() => validate()}
          text="Сохранить"
        />
      </div>
    </div>
  );
};

export default CategoryModal;
