import * as React from "react";
import styled from "styled-components";
import { Button, FormField, Select } from "@omnichat/arm_ui_kit";

import { Input } from "../../../Components";
import ModalWindow from "../../../Components/ModalWindow";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { useState } from "react";

const CreateChannelModalRoot = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
`;

const Header = styled.div`
  font-size: 22px;
  font-weight: 700;
  color: #2a323f;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
  padding-bottom: 15px;
`;

const FiledSeparator = styled.div`
  margin-bottom: 20px;
`;

const ActionButtons = styled.div`
  justify-content: center;
  display: flex;
  & > button {
    margin-right: 10px;
  }
`;

export function CreateChannel({ open, handleClose }) {
  const [name, setName] = useState("");
  const [type, setType] = useState(null);
  const [error, setError] = useState("");
  const [typeError, setTypeError] = useState("");
  /**
   * Валидация формы.
   */
  function handleValidate() {
    !name.trim() && setError("Поле не может быть пустым");
    !type && setTypeError("Поле не может быть пустым");
    return name.trim() && type;
  }

  const handleSave = () => {
    if (handleValidate()) {
      httpRequest("POST", "bff/settings/channels", {
        body: { name, type }
      }).then((res) => {
        if (res?.success) {
        } else {
          console.error(res?.error);
        }
        handleClose?.();
      });
    }
  };

  /**
   * Обработчик ввода символов.
   *
   * @param e Событие ввода.
   */
  function handleInputChange(value: string) {
    setName(value);

    error && value && setError("");
  }

  /**
   * Обработчик выбора типа
   * @param val тип канала
   */
  function handleTypeChange([val]) {
    setType(val);
    typeError && setTypeError("");
  }

  return (
    <ModalWindow open={open} onCloseModal={handleClose} isButtonClose>
      <CreateChannelModalRoot>
        <Header>Создание канала</Header>

        <Input
          label="Название"
          required
          value={name}
          onChange={handleInputChange}
          placeholder="Введите название канала"
          isError={!!error}
        />

        <FiledSeparator />

        <FormField
          label="Тип канала"
          required
          errors={typeError ? [typeError] : undefined}
        >
          <Select
            options={[
              { value: 9, label: "Веб-чат" },
              { value: 10, label: "Мобильное приложение" }
            ]}
            selected={type ? [type] : []}
            onSelectOption={handleTypeChange}
            isError={!!typeError}
            required
          ></Select>
        </FormField>

        <FiledSeparator />

        <ActionButtons>
          <Button
            text="Сохранить"
            theme="green"
            type="default"
            onClick={handleSave}
          ></Button>
        </ActionButtons>
      </CreateChannelModalRoot>
    </ModalWindow>
  );
}
