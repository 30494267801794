/**
 * Коллекция типов сообщений.
 *
 * @prop {number} Default По умолчанию.
 * @prop {number} CreateAppeal Создание обращения.
 * @prop {number} CloseAppeal Закрытие обращения.
 * @prop {number} TransferBySystem Системный перевод обращения.
 * @prop {number} TransferByUser Ручной перевод.
 * @prop {number} ReopenAppeal Переоткрытие.
 * @prop {number} Waiting Уход в ожидание.
 * @prop {number} Greeting Приветствие.
 *
 */
export enum EMessagesType {
  Default = 0,
  CreateAppeal = 1,
  CloseAppeal = 2,
  TransferBySystem = 4,
  TransferByUser = 5,
  ReopenAppeal = 6,
  Waiting = 9,
  Greeting = 12,
  AnswerDelay = 16
}

/**
 * Коллекция типов данных, содержащихся в сообщении.
 *
 * @prop {number} System Системное сообщение.
 * @prop {number} Text Текстовое сообщение.
 * @prop {number} Image Сообщение с изображением.
 * @prop {number} Video Сообщение с видео.
 * @prop {number} Location Сообщение с координатами местонахождения.
 * @prop {number} File Сообщение, содержащее файл.
 * @prop {number} Sticker Сообщение, содержащее ???
 */
export enum EMediaType {
  System,
  Text,
  Image,
  Video,
  Location,
  File,
  Sticker
}
