import { throttle, put } from "redux-saga/effects";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { actions } from "../Store/Appeals.reducer";

function* getAppealsSaga({ payload: { page, filters }, type }) {
  yield put(actions.startLoading(type));

  const query = { page, size: 20, ...filters };

  try {
    const res = yield httpRequest("GET", `bff/dashboard/appeals`, {
      query
    });

    if (res.success) {
      if (!res.data.length) {
        yield put(actions.setLastPage(page));
      }
      if (page === 0) {
        yield put(actions.setAppeals(res.data));
      } else {
        yield put(actions.addAppeals(res.data));
      }

      yield put(actions.setPage(page));
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put(actions.finishLoading(type));
  }
}

export default function*() {
  yield throttle(300, [actions.getAppeals], getAppealsSaga);
}
