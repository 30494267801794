import * as React from "react";
import { connect } from "react-redux";
import { StoreState } from "../../../ReactCore/store/types";

import Modal from "../../../arm/Wrappers/Modal";
import Informer from "../../../arm/Wrappers/Informer.js";

import { CategoryModal } from "../../../ReactFeatures/CategoryModal";
import { RepliesTemplateEditModal } from "../../../ReactFeatures/RepliesTemplateEditModal";
import { CategoriesList } from "../../../ReactFeatures/CategoriesList";
import { RepliesList } from "../../../ReactFeatures/RepliesList";
import SettingsSection from "../../../ReactFeatures/Common/Components/SettingsSection/SettingsSection";
import ThemeProvider from "../../../Components/ThemeProvider";

/**
 * Модель собственных свойств компонента.
 *
 */
interface IOwnProps {}

/**
 * Модель свойств, полученных из store.
 *
 */
interface IStateProps {}

/**
 * Модель экшенов.
 *
 */
interface IDispatchProps {}

type RepliesTemplateSettingsProviderProps = IOwnProps &
  IStateProps &
  IDispatchProps;

/**
 * Провайдер фичи RepliesTemplateSettings.
 */
class RepliesTemplateSettingsProvider extends React.PureComponent<
  RepliesTemplateSettingsProviderProps
> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  openRepliesTemplateEditModal = () => {
    const modalId = `RepliesTemplateEditModal`;

    const modal = new Modal(modalId, 600, (close) => {
      const onClose = () => {
        const informer = new Informer("Данные успешно обновлены", 3000);
        informer.show();
        close();
      };

      return <RepliesTemplateEditModal closeModal={onClose} />;
    });
    modal.open();
  };

  openCategoryModal = (categoryId?: number) => {
    const modalId = `CategoryModal`;

    const modal = new Modal(modalId, 640, (close) => {
      const onClose = () => {
        const informer = new Informer("Данные успешно обновлены", 3000);
        informer.show();
        close();
      };

      return (
        <ThemeProvider>
          <CategoryModal categoryId={categoryId} close={() => onClose()} />;
        </ThemeProvider>
      )
    });
    modal.open();
  };

  render() {
    return (
      <SettingsSection
        title="Управление шаблонами ответов"
        menu={
          <CategoriesList
            openEditCategoryModal={(id) => this.openCategoryModal(id)}
          />
        }
        content={
          <RepliesList
            openRepliesTemplateModal={(id) =>
              this.openRepliesTemplateEditModal(id)
            }
          />
        }
      />
    );
  }
}

const mapStateToProps = (state: StoreState.State): IStateProps => ({});

const mapDispatchToProps = (dispatch) => ({});

const Connect = connect(
  mapStateToProps,
  mapDispatchToProps
)(RepliesTemplateSettingsProvider);

export default Connect;
