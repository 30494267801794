import { StoreState } from "frontend/src/ReactCore/store/types";
import { createSelector } from "reselect";
import { IClient } from "../Dialog/Interfaces";
import { IAppealInfo } from "../Common/Interfaces/Appeal.interfaces";

export const getAppealData = (state: StoreState.State) => {
  return state.AppealInfo.data;
};

export const getDialogId = createSelector(
  getAppealData,
  (appeal: IAppealInfo) => {
    return appeal && appeal.dialogId ? appeal.dialogId : null;
  }
);

export const getClientData = createSelector(
  getAppealData,
  (appeal: IAppealInfo) => {
    return appeal && appeal.client ? appeal.client : null;
  }
);

export const getClientRemarkFio = createSelector(
  getClientData,
  (client: IClient) => {
    return client && client.remarkFio;
  }
);

export const getClientRemarkPhone = createSelector(
  getClientData,
  (client: IClient) => {
    return client && client.remarkPhone;
  }
);

export const getClientBirthDay = createSelector(
  getClientData,
  (client: IClient) => {
    return client && client.birthDay;
  }
);

export const getChannelTypeId = createSelector(
  getClientData,
  (client: IClient) => client?.channel?.channelTypeId ?? null
);

export const getChannelId = createSelector(getClientData, (client: IClient) => {
  return client && client.channel && client.channel.id
    ? client.channel.id
    : null;
});

export const getClientId = createSelector(getClientData, (client: IClient) => {
  return client && client.id;
});

export const getContextData = createSelector(
  getAppealData,
  (appeal: IAppealInfo) => {
    return appeal?.context ? appeal.context : null;
  }
);
