import React from "react";

import c from "classnames";
import { SxProps, Theme } from "@mui/material";

import {
  MaskInput,
  Button,
  Select,
  FormField,
  DateInput
} from "@omnichat/arm_ui_kit";
import { Input, Checkbox } from "../../../../Components";

import { isEmpty } from "@omnichat/arm_ui_kit";

import * as s from "./styles.module.scss";

interface IData {
  client: string;
  appeal: string;
  channel: string;
  who_processed: string;
  status: string;
  region: string;
  mrf: string;
  rf: string;
  sl: string;
  csi: string;
  remark_fio: string;
  remark_phone: string;
  remark_birthday: string;
  without_themes?: string;
}

export interface IFilterOptions {
  sl: object[];
  csi: object[];
  whoProcessed: object[];
  mrf: object[];
  rf: object[];
  region: object[];
  status: object[];
  channel: object[];
}

/**
 * Модель свойств панели фильтрации.
 *
 * @prop {boolean} [isTimeAvailable] Признак, который показывает доступность проставить время.
 */
interface IFilterPanel {
  data: IData;
  changedFields: object;
  options: IFilterOptions;
  onChange: (fieldsData: object) => void;
  onConfirm: () => void;
  onReset: () => void;
  confirmInProgress: boolean;
}

const ExpandedFilterFilds: React.FunctionComponent<IFilterPanel> = ({
  data,
  changedFields = {},
  options,
  onChange,
  onConfirm,
  onReset,
  confirmInProgress
}): JSX.Element => {
  const getExtraClass = (name: string): [{ [key: string]: string }] | [] =>
    changedFields[name] ? [s["changedField"]] : [];

  const getExtraSX = (name: string): SxProps<Theme> => (theme) =>
    changedFields[name] && {
      border: `2px solid ${theme.palette.primary.main}`
    };

  return (
    <div className={s["filterExpanded"]}>
      <div className={s["filterExpandedBody"]}>
        {typeof data.client !== "undefined" && (
          <div className={s["filterPanelCol"]}>
            <Input
              name="client"
              label="Клиент"
              placeholder="Введите контакт или имя клиента"
              value={data.client || ""}
              onChange={(value, e) => {
                onChange({
                  ...data,
                  client: value
                });
              }}
              extraSX={{ inputBox: getExtraSX("client") }}
            />
          </div>
        )}
        {typeof data.who_processed !== "undefined" && options.whoProcessed && (
          <div className={s["filterPanelCol"]}>
            <FormField label="Агент">
              <Select
                name="who_processed"
                options={options.whoProcessed}
                selected={[data.who_processed]}
                onSelectOption={(value) =>
                  onChange({
                    ...data,
                    who_processed: value[0]
                  })
                }
                extraClassWrapper={getExtraClass("who_processed")}
              />
            </FormField>
          </div>
        )}
        {typeof data.channel !== "undefined" && options.channel && (
          <div className={s["filterPanelCol"]}>
            <FormField label="Канал">
              <Select
                name="channel"
                options={options.channel}
                selected={[data.channel]}
                onSelectOption={(value) =>
                  onChange({
                    ...data,
                    channel: value[0]
                  })
                }
                extraClassWrapper={getExtraClass("channel")}
              />
            </FormField>
          </div>
        )}
        {typeof data.mrf !== "undefined" && options.mrf && (
          <div className={s["filterPanelCol"]}>
            <FormField label="МРФ">
              <Select
                name="mrf"
                options={options.mrf}
                selected={[data.mrf]}
                onSelectOption={(value) =>
                  onChange({
                    ...data,
                    mrf: value[0]
                  })
                }
                extraClassWrapper={getExtraClass("mrf")}
              />
            </FormField>
          </div>
        )}
        {typeof data.rf !== "undefined" && options.rf && (
          <div className={s["filterPanelCol"]}>
            <FormField label="РФ">
              <Select
                name="rf"
                options={options.rf}
                selected={[data.rf]}
                onSelectOption={(value) =>
                  onChange({
                    ...data,
                    rf: value[0]
                  })
                }
                extraClassWrapper={getExtraClass("rf")}
              />
            </FormField>
          </div>
        )}
        {typeof data.region !== "undefined" && options.region && (
          <div className={s["filterPanelCol"]}>
            <FormField label="Регион">
              <Select
                name="region"
                options={options.region}
                selected={[data.region]}
                onSelectOption={(value) =>
                  onChange({
                    ...data,
                    region: value[0]
                  })
                }
                extraClassWrapper={getExtraClass("region")}
              />
            </FormField>
          </div>
        )}
        {typeof data.status !== "undefined" && options.status && (
          <div className={s["filterPanelCol"]}>
            <FormField label="Статус">
              <Select
                name="status"
                options={options.status}
                selected={[data.status]}
                onSelectOption={(value) =>
                  onChange({
                    ...data,
                    status: value[0]
                  })
                }
                extraClassWrapper={getExtraClass("status")}
              />
            </FormField>
          </div>
        )}
        {typeof data.sl !== "undefined" && options.sl && (
          <div className={s["filterPanelCol"]}>
            <FormField label="Уровень SL">
              <Select
                name="sl"
                options={options.sl}
                selected={[data.sl]}
                onSelectOption={(value) =>
                  onChange({
                    ...data,
                    sl: value[0]
                  })
                }
                extraClassWrapper={getExtraClass("sl")}
              />
            </FormField>
          </div>
        )}
        {typeof data.csi !== "undefined" && options.csi && (
          <div className={s["filterPanelCol"]}>
            <FormField label="Оценка CSI">
              <Select
                name="csi"
                options={options.csi}
                selected={[data.csi]}
                onSelectOption={(value) =>
                  onChange({
                    ...data,
                    csi: value[0]
                  })
                }
                extraClassWrapper={getExtraClass("csi")}
              />
            </FormField>
          </div>
        )}
        {typeof data.remark_fio !== "undefined" && (
          <div className={s["filterPanelCol"]}>
            <Input
              name="remark_fio"
              label="ФИО"
              placeholder="Не указано"
              value={data.remark_fio || ""}
              onChange={(value) =>
                onChange({
                  ...data,
                  remark_fio: value
                })
              }
              extraSX={{ inputBox: getExtraSX("remark_fio") }}
            />
          </div>
        )}
        {typeof data.remark_phone !== "undefined" && (
          <div className={c(s["filterPanelCol"], s["fieldRemarkPhone"])}>
            <FormField label="Телефон">
              <MaskInput
                name="remark_phone"
                mask={"+7 (999) 999-99-99"}
                onKeyDown={(e) => e.key === "_" && e.preventDefault()}
                onChange={(e) =>
                  onChange({
                    ...data,
                    remark_phone: e.currentTarget.value
                  })
                }
                value={data.remark_phone}
                label=""
                placeholder="Не указан"
                extraClass={getExtraClass("remark_phone")}
              />
            </FormField>
          </div>
        )}
        {typeof data.remark_birthday !== "undefined" && (
          <div className={s["filterPanelCol"]}>
            <FormField label="Дата рождения">
              <div className={s["filterPanelDateInput"]}>
                <DateInput
                  name="remark_birthday"
                  popupPosition="bottom"
                  selected={data.remark_birthday || ""}
                  onResetValue={() =>
                    onChange({
                      ...data,
                      remark_birthday: ""
                    })
                  }
                  onSelectDay={(value) =>
                    onChange({
                      ...data,
                      remark_birthday: value
                    })
                  }
                  isTimeAvailable={false}
                  extraClass={getExtraClass("remark_birthday")}
                />
              </div>
            </FormField>
          </div>
        )}
        {typeof data.without_themes !== "undefined" && (
          <div className={s["filterPanelColCheckbox"]}>
            <FormField label="Ожидание">
              <Checkbox
                caption="Достигло времени ожидания, нет тематики"
                captionPosition="end"
                checked={data.without_themes === "1"}
                onChange={(e) =>
                  onChange({
                    ...data,
                    without_themes: e.currentTarget.checked ? "1" : "0"
                  })
                }
              />
            </FormField>
          </div>
        )}

        <div className={s["filterExpandedButtons"]}>
          {onReset && !isEmpty(changedFields) && (
            <div className={s["filterExpandedReset"]}>
              <Button
                type="bare"
                text="Сбросить"
                iconName="cross"
                iconColor="gray"
                onClick={onReset}
                disabled={!!confirmInProgress}
              />
            </div>
          )}

          <div>
            <Button
              type="fill"
              theme="blue"
              text="Применить"
              onClick={onConfirm}
              disabled={!!confirmInProgress}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpandedFilterFilds;
