import { StoreState } from "../../../ReactCore/store/types";
import { DTO } from "../UsersMultiProfileModal.d";

export const getErrorMessage = (state: StoreState.State): string =>
  state.UsersMultiProfileModal.errorMessage;

export const getFormData = (state: StoreState.State): DTO => null;

export const getDataSendHandling = (state: StoreState.State): boolean =>
  state.UsersMultiProfileModal.status.dataSendHandling;
