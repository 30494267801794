import React, { FC } from "react";

import {
  getMessageSignature,
  getMessageTo,
  getMessageFrom,
  getInfoTransferFrom,
  getInfoUserField,
  getMediaUrl,
  getMessageSender,
  getMessageSide,
  getMessageTextContainerStyle,
  getOperatorsName,
  getTimeStampInMilliseconds,
  getUniqueId,
  getUserAvatar,
  isImageMessageConstructorShown,
  isEmailMessageConstructorShown,
  isLocationMessageShown,
  isMessageTextShown,
  isUploadFileLinkShown,
  resolveMessage,
  getMessageTime,
  excludeFileOrImage
} from "../Utils";
import { EMessagesType } from "../../Common/Enums/Message.enums";
import { IMessage } from "../../Common/Interfaces/Message.interfaces";
import ImageMessageConstructor from "./Image.constructor";
import SystemMessage from "./SystemMessage/SystemMessage";
import DialogMessage from "./DialogMessage/DialogMessage";
import LocationMessage from "./LocationMessage/LocationMessage";
import UploadFileLink from "./UploadFileLink/UploadFileLink";
import EmailMessage from "./EmailMessage";

/**
 * Свойства компонента по отображению блока с сообщениями.
 *
 * @prop {IMessage[]} messages Массив сообщений для отображения.
 * @prop {string} filesUrl Ссылка на расположение файлов для конкатенации.
 * @prop {string} avatarUrl Ссылка на расположение аватара.
 * @prop {Function} onShowImageViewer Колбэк на отображения компонента для просмотра изображения.
 * @prop {boolean} [isExpanded] Флаг, который показывает, развернуты ли системные сообщения по умолчанию.
 */
export interface IMessageBox {
  messages: IMessage[];
  filesUrl: string;
  avatarUrl: string;
  onShowImageViewer: (imageSrc: string) => void;
  isExpanded?: boolean;
  isMobile?: boolean;
  disableBubbleAvatars?: boolean;
  hideBubbleFiles?: boolean;
  clientName: string;
}

/**
 * Блок с сообщениями.
 */
const MessageBox: FC<IMessageBox> = ({
  messages,
  filesUrl,
  avatarUrl,
  onShowImageViewer,
  isExpanded = false,
  isMobile = false,
  clientName,
  disableBubbleAvatars,
  hideBubbleFiles
}): JSX.Element => {
  /**
   * Отрисует компонент UploadFileLink.
   *
   * @param {IMessage} messageData Данные о сообщении.
   */
  const renderUploadFileLink = (messageData: IMessage) => {
    return (
      isUploadFileLinkShown(messageData) && (
        <div style={{ maxWidth: "280px" }}>
          <UploadFileLink
            fileUrl={filesUrl + getMediaUrl(messageData.mediaUrl)}
          />
        </div>
      )
    );
  };

  /**
   * Отрисует компонент LocationMessage.
   *
   * @param {IMessage} messageData Данные о сообщении.
   */
  const renderLocationMessage = (messageData: IMessage) => {
    return (
      isLocationMessageShown(messageData) && (
        <div style={{ maxWidth: "280px" }}>
          <LocationMessage
            latitude={messageData.latitude}
            longitude={messageData.longitude}
            locationTumbSrc={
              messageData.mediaUrl && filesUrl + messageData.mediaUrl
            }
          />
        </div>
      )
    );
  };

  /**
   * Отрисует компонент ImageMessageConstructor.
   *
   * @param {IMessage} messageData Данные о сообщении.
   */
  const renderImageMessageConstructor = (messageData: IMessage) => {
    return (
      isImageMessageConstructorShown(messageData) && (
        <div style={{ maxWidth: "280px" }}>
          <ImageMessageConstructor
            filesUrl={filesUrl}
            messageData={messageData}
            onShowImageViewer={onShowImageViewer}
          />
        </div>
      )
    );
  };

  /**
   * Отрисует текст сообщения.
   *
   * @param {IMessage} messageData Данные о сообщении.
   */
  const renderMessageText = (messageData: IMessage) => {
    return (
      isMessageTextShown(messageData) && (
        <div
          style={getMessageTextContainerStyle(messageData?.mediaType)}
          dangerouslySetInnerHTML={{
            __html: resolveMessage(messageData.text)
          }}
        />
      )
    );
  };

  /**
   * Отрисует контент письма.
   *
   * @param {IMessage} messageData Данные о сообщении.
   */
  const renderEmail = (messageData: IMessage) => {
    return (
      isEmailMessageConstructorShown(messageData) && (
        <EmailMessage
          avatar={
            messageData.user && getUserAvatar(messageData.user, avatarUrl)
          }
          subject={messageData.additionalData?.subject || "(Без темы)"}
          sentFrom={`< ${messageData.additionalData?.sent_from} >`}
          sentTo={`${messageData.additionalData?.sent_to || ""}`}
          emailDate={`${getMessageTime(
            new Date(messageData.additionalData?.email_date)
          )}`}
          attachments={messageData.additionalData?.attachments || []}
          client={messageData.clientId && clientName}
          plainTextData={messageData.text}
          htmlData={messageData.additionalData?.html_text}
        />
      )
    );
  };

  const switchMessages = (messageData: IMessage): JSX.Element | void => {
    switch (messageData.type) {
      case EMessagesType.Default:
        return (
          excludeFileOrImage(hideBubbleFiles, messageData) && (
            <DialogMessage
              key={getUniqueId(messageData)}
              infoText={getMessageSignature({
                type: getMessageSender(messageData),
                time: getTimeStampInMilliseconds(messageData.date),
                name: getOperatorsName(messageData.user),
                channel: messageData.channel ? messageData.channel.name : ""
              })}
              side={getMessageSide(messageData)}
              avatar={
                isMobile || disableBubbleAvatars
                  ? null
                  : messageData.user &&
                    getUserAvatar(messageData.user, avatarUrl)
              }
            >
              <>
                {renderEmail(messageData)}

                {renderUploadFileLink(messageData)}

                {renderLocationMessage(messageData)}

                {renderImageMessageConstructor(messageData)}

                {renderMessageText(messageData)}
              </>
            </DialogMessage>
          )
        );
      case EMessagesType.Greeting:
      case EMessagesType.AnswerDelay:
        return (
          <DialogMessage
            key={getUniqueId(messageData)}
            infoText={getMessageSignature({
              type: getMessageSender(messageData),
              time: getTimeStampInMilliseconds(messageData.date)
            })}
            side="right"
          >
            <div style={{ maxWidth: "280px" }}>{messageData.text}</div>
          </DialogMessage>
        );
      case EMessagesType.Waiting:
        return (
          <SystemMessage
            key={getUniqueId(messageData)}
            number={messageData.appealId}
            action={messageData.type}
            info={getMessageSignature({
              type: getMessageSender(messageData),
              time: getTimeStampInMilliseconds(messageData.date)
            })}
            isExpanded={!!isExpanded}
            uniqueId={getUniqueId(messageData)}
          />
        );
      case EMessagesType.TransferBySystem:
        if (!messageData.transfer) {
          return;
        }
        return (
          <SystemMessage
            key={getUniqueId(messageData)}
            number={messageData.appealId}
            action={messageData.type}
            from={getMessageFrom(messageData.transfer)}
            to={getMessageTo(messageData.transfer)}
            info={getMessageSignature({
              type: getMessageSender(messageData),
              time: getTimeStampInMilliseconds(messageData.date)
            })}
            isExpanded={!!isExpanded}
            uniqueId={getUniqueId(messageData)}
          />
        );
      case EMessagesType.TransferByUser:
        if (!messageData.transfer) {
          return;
        }
        return (
          <SystemMessage
            key={getUniqueId(messageData)}
            reason={messageData.text}
            number={messageData.appealId}
            action={messageData.type}
            from={getMessageFrom(messageData.transfer)}
            to={getMessageTo(messageData.transfer)}
            info={getMessageSignature({
              type: getMessageSender(messageData),
              time: getTimeStampInMilliseconds(messageData.date),
              name: getInfoUserField(messageData)
            })}
            isExpanded={!!isExpanded}
            uniqueId={getUniqueId(messageData)}
          />
        );
      case EMessagesType.CreateAppeal:
        return (
          <SystemMessage
            key={getUniqueId(messageData)}
            reason={messageData.text}
            number={messageData.appealId}
            action={messageData.type}
            info={getMessageSignature({
              type: getMessageSender(messageData),
              time: getTimeStampInMilliseconds(messageData.date)
            })}
            isExpanded={!!isExpanded}
            uniqueId={getUniqueId(messageData)}
          />
        );
      case EMessagesType.ReopenAppeal:
        return (
          <SystemMessage
            key={getUniqueId(messageData)}
            reason={messageData.text}
            number={messageData.appealId}
            action={messageData.type}
            info={getMessageSignature({
              type: getMessageSender(messageData),
              time: getTimeStampInMilliseconds(messageData.date),
              name: getInfoTransferFrom(messageData.transfer)
            })}
            isExpanded={!!isExpanded}
            uniqueId={getUniqueId(messageData)}
          />
        );
      case EMessagesType.CloseAppeal:
        return (
          <SystemMessage
            key={getUniqueId(messageData)}
            reason={messageData.text}
            number={messageData.appealId}
            action={messageData.type}
            info={getMessageSignature({
              type: getMessageSender(messageData),
              time: getTimeStampInMilliseconds(messageData.date),
              name: getInfoUserField(messageData)
            })}
            csi={messageData.csi}
            isExpanded={!!isExpanded}
            uniqueId={getUniqueId(messageData)}
          />
        );
    }
  };

  return <>{messages.map(switchMessages)}</>;
};

export default MessageBox;
