import React, { PureComponent } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { StoreState } from "../../../ReactCore/store/types";

import Modal from "../../../arm/Wrappers/Modal";
import { Preloader } from "@omnichat/arm_ui_kit";

import ThematicModal from "./ThematicModal.provider";
import * as ThematicSelectors from "../ThematicModal.selectors";
import {
  getThemes,
  getThemesFields,
  setThemesFields
} from "../Actions/ThematicModal.action";
import {
  setOriginAppealThemes,
  setOriginAppealService,
  sendThemesData
} from "../Actions/ThematicInfoPanel.action";
import ThematicsInfo from "../Components/ThematicsInfo/ThematicsInfo";
import { ModalWindow } from "../../../Components";

const Wrap = styled.div`
  display: block;
  min-height: 50px;
  position: relative;
`;

/**
 * Модель собственных свойств компонента.
 *
 */
interface IOwnProps {
  appealId: string;
}

/**
 * Модель свойств, полученных из store.
 *
 */
interface IStoreProps {
  appealThemes: string[];
  appealServices: string[];
  canEdit: boolean;
  isLoading: boolean;
}

/**
 * Модель экшенов.
 *
 */
interface IDispatchProps {
  getThemes: (appealId: string) => void;
  setOriginAppealThemes: (data: []) => any;
  setOriginAppealService: (data: []) => any;
  sendThemesData: (data: { appealId: string; onClose: () => void }) => void;
  getThemesFields: (appealId: string) => any;
  resetThemesFields: () => void;
}

interface IState {
  modalOpen: boolean;
}

type ThematicInfoPanelProviderProps = IOwnProps & IStoreProps & IDispatchProps;

/**
 * Провайдер фичи ThematicInfoPanelProvider.
 */
class ThematicInfoPanelProvider extends PureComponent<
  ThematicInfoPanelProviderProps,
  IState
> {
  constructor(props) {
    super(props);

    // this.state = { modalOpen: false };
  }

  componentDidMount() {
    const { appealId, getThemes, getThemesFields } = this.props;
    getThemes(appealId);
    getThemesFields(appealId);
  }

  componentWillUnmount() {
    const {
      setOriginAppealThemes,
      setOriginAppealService,
      resetThemesFields
    } = this.props;
    setOriginAppealThemes([]);
    setOriginAppealService(null);
    resetThemesFields();
  }

  openThematicsModal() {
    const { appealId, sendThemesData, getThemes } = this.props;
    const modalId = "ThematicModal";
    const modal = new Modal(modalId, 650, (close) => (
      <div style={{ padding: "25px" }}>
        <ThematicModal
          onConfirm={() =>
            sendThemesData({
              appealId,
              onClose: () => {
                getThemes(appealId);
                close();
              }
            })
          }
          appealId={appealId}
        />
      </div>
    ));
    modal.open();
  }

  render() {
    const {
      canEdit,
      appealThemes,
      appealServices,
      isLoading,
      appealId,
      getThemes,
      sendThemesData
    } = this.props;

    return (
      <Wrap>
        <Preloader size="mini" show={isLoading}>
          <ThematicsInfo
            canEdit={canEdit}
            thematics={appealThemes}
            services={appealServices}
            // onEditHandler={() => this.setState({ modalOpen: true })}
            onEditHandler={this.openThematicsModal.bind(this)}
          />

          {/* <ModalWindow
            open={this.state.modalOpen}
            onCloseModal={() => this.setState({ modalOpen: false })}
            isButtonClose
          >
            <ThematicModal
              onConfirm={() =>
                sendThemesData({
                  appealId,
                  onClose: () => {
                    getThemes(appealId);
                    this.setState({ modalOpen: false });
                  }
                })
              }
              appealId={appealId}
            />
          </ModalWindow> */}
        </Preloader>
      </Wrap>
    );
  }
}

const mapStateToProps = (state: StoreState.State): IStoreProps => ({
  appealThemes: ThematicSelectors.getThemesForInfoPanel(state),
  appealServices: ThematicSelectors.getServisesForInfoPanel(state),
  canEdit: ThematicSelectors.getCanEditThemes(state),
  isLoading: ThematicSelectors.getIsLoading(state)
});

const mapDispatchToProps = (dispatch) => ({
  sendThemesData: (data) => dispatch(sendThemesData(data)),
  getThemes: (appealId) => dispatch(getThemes(appealId)),
  setOriginAppealThemes: (data) => dispatch(setOriginAppealThemes(data)),
  setOriginAppealService: (data) => dispatch(setOriginAppealService(data)),
  getThemesFields: (appealId) => dispatch(getThemesFields(appealId)),
  resetThemesFields: () => dispatch(setThemesFields([]))
});

const Connect = connect(
  mapStateToProps,
  mapDispatchToProps
)(ThematicInfoPanelProvider);

export default Connect;
