import React, { FC, useState } from "react";
import c from "classnames";

import { Avatar, ScrollBox, Icon } from "@omnichat/arm_ui_kit";

import * as s from "./EmailMessage.module.scss";

type Attachment = {
  filename: string;
  type: string;
  mediaUrl: string;
};

export interface IEmailMessageProps {
  plainTextData: string;
  htmlData: string;
  client?: string;
  avatar?: string;
  subject: string;
  emailDate: string;
  sentFrom: string;
  sentTo: string;
  attachments?: Attachment[];
  onOpen?: () => void;
}

const EmailMessage: FC<IEmailMessageProps> = ({
  client,
  subject,
  sentFrom,
  sentTo,
  emailDate,
  avatar,
  attachments,
  onOpen,
  plainTextData,
  htmlData
}): JSX.Element => {
  // Компонент используется в диалоге или модалке
  const isBubble = !!onOpen;
  const parser = new DOMParser();

  return (
    <>
      <div className={c(s["emailMessage"])}>
        <div className={c(s["emailMessageHeader"])}>
          {typeof avatar === "string" && (
            <div className={s["emailMessageSenderAvatar"]}>
              <Avatar size="normal" url={avatar} person="user" />
            </div>
          )}
          <div className={c(s["emailMessageInfo"])}>
            <div className={c(s["emailMessageFrom"])}>
              <span>От: </span>
              <span className={c(s["emailMessageFromName"])}>{`${client ||
                ""} `}</span>
              <span className={c(s["value"], s["lowercase"])}>{sentFrom}</span>
            </div>
            <div className={c(s["emailMessageTo"])}>
              Кому:{" "}
              <span className={c(s["value"], s["lowercase"])}>{sentTo}</span>
            </div>
            <div className={c(s["emailMessageSubject"])}>
              Тема: <span className={c(s["value"])}>{subject}</span>
            </div>
          </div>
          <div className={c(s["emailMessageDate"])}>{emailDate}</div>
        </div>
        <div className={c(s["emailMessageBody"])}>
          <ScrollBox
            autoHeight
            autoHeightMax={isBubble ? 263 : 400}
            customScrollControlStyles={{
              borderRadius: "2px",
              backgroundColor: "#f0f0f0"
            }}
          >
            <div
              className={c(s["emailMessageScrollContainer"])}
              dangerouslySetInnerHTML={{
                __html: isBubble ? plainTextData : htmlData
              }}
            ></div>
          </ScrollBox>
        </div>
        {isBubble && (
          <div className={c(s["emailMessageMore"])}>
            <div className={c(s["emailMessageMoreBtn"])} onClick={onOpen}>
              Раскрыть полностью
            </div>
          </div>
        )}
        <div className={c(s["emailMessageAttachments"])}>
          <ScrollBox
            autoHeight
            autoHeightMax={143}
            customScrollControlStyles={{ borderRadius: "2px" }}
          >
            <div className={c(s["emailMessageAttachmentsContainer"])}>
              {attachments?.map((a, i) => {
                const [name, ext] = a.filename?.split(".");
                return (
                  <a
                    key={`${i}-${a.filename}`}
                    className={c(s["emailMessageAttachment"])}
                    href={`/fileStorage/files/thumb/${a.mediaUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon
                      color="blue"
                      name="attach_file"
                      width="20px"
                      height="20px"
                    />
                    <div
                      className={c(s["emailMessageAttachmentNameContainer"])}
                    >
                      <span className={c(s["emailMessageAttachmentName"])}>
                        {name}
                      </span>
                      <span
                        className={c(s["emailMessageAttachmentExt"])}
                      >{`.${ext}`}</span>
                    </div>
                  </a>
                );
              })}
            </div>
          </ScrollBox>
        </div>
      </div>
    </>
  );
};

export default EmailMessage;
