import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { Preloader } from "@omnichat/arm_ui_kit";
import { StoreState } from "../../../ReactCore/store/types";

import { ITransferFormData } from "../Interfaces";

import TransferAppealModal from "../Components/TransferAppealModal/TransferAppealModal";
import * as TransferAppealModalSelectors from "../Selectors";
import * as DictionariesSelectors from "../../Dictionaries/Store/Dictionaries.selector";
import {
  getTransferDictionary,
  cleanTransferDictionary
} from "../../Dictionaries/Store/Dictionaries.actions";
import {
  setMoveAppealModalFormData,
  cleanMoveAppealModalFormData
} from "../Reducers/MoveAppealModal.reducer";

/**
 * Модель свойств, полученных из store.
 *
 * @prop {IAsyncData} asyncAvailableHotKeysData Контейнер с данными МО трансфера,
 * которые получены асинхронно.
 */
interface IStoreProps {
  formData: ITransferFormData;
  transferList: [];
}

/**
 * Экшены.
 */
interface IDispatchProps {
  getTransferDictionary: any;
  cleanTransferDictionary: any;
  setMoveAppealModalFormData: any;
  cleanMoveAppealModalFormData: any;
}

/**
 * Интерфейс собственных свойств компонента.
 *
 * @prop {number} appealId Идентификатор обращения.
 */
interface IOwnProps {
  header;
  appealId: string;
  close: () => void;
  onConfirm;
}

type TProps = IOwnProps & IDispatchProps & IStoreProps;

/**
 * Интерфейс состояния компонента.
 *
 * @prop {number} page Номер запрашиваемой страницы.
 * @prop {boolean} isPending Идет ли загрузка в данный момент.
 */
interface IState {
  page: number;
  isPending: boolean;
}

/**
 * Компонент с общей логикой для компонентов ReopenAppealModal и TransferAppealModal
 */
class MoveAppealModalProvider extends PureComponent<TProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      isPending: false
    };
  }

  onConfirmTransfer() {
    const { appealId, formData, close, onConfirm } = this.props;
    onConfirm({
      appealId: Number(appealId),
      groupId: Number(formData.target.groupId) || null,
      userId: Number(formData.target.userId) || null,
      comment: formData.comment,
      cb: close
    });
  }

  componentWillUnmount() {
    this.props.cleanMoveAppealModalFormData();
    this.props.cleanTransferDictionary();
  }

  onChangeFormData(newFormData) {
    const { setMoveAppealModalFormData } = this.props;
    setMoveAppealModalFormData(newFormData);
  }

  onSearch(target, query) {
    const {
      appealId,
      getTransferDictionary,
      cleanTransferDictionary
    } = this.props;
    this.setState({ page: 0 });
    cleanTransferDictionary();
    this.setState({
      isPending: true
    });
    getTransferDictionary({
      target,
      appealId,
      query,
      page: 0,
      cb: () => {
        this.setState({
          isPending: false
        });
      }
    });
  }

  onNextPage(target, query) {
    const newPage = this.state.page + 1;
    this.setState({ page: newPage }, () => {
      this.props.getTransferDictionary({
        target,
        appealId: this.props.appealId,
        query: query,
        page: newPage
      });
    });
  }

  render() {
    const { formData, transferList = [], close, header } = this.props;

    return (
      <>
        <Preloader size="mini" show={!true}>
          <TransferAppealModal
            header={header}
            formData={formData}
            transferList={transferList}
            onNextPage={this.onNextPage.bind(this)}
            onChangeFormData={this.onChangeFormData.bind(this)}
            onSearch={this.onSearch.bind(this)}
            onConfirmAction={this.onConfirmTransfer.bind(this)}
            onCancelAction={close}
            isPendingTargetList={this.state.isPending}
          />
        </Preloader>
      </>
    );
  }
}

const mapStateToProps = (state: StoreState.State): IStoreProps => ({
  formData: TransferAppealModalSelectors.getFormData(state),
  transferList: DictionariesSelectors.getMoveAppealList(state)
});

const mapDispatchToProps = {
  setMoveAppealModalFormData,
  cleanMoveAppealModalFormData,
  getTransferDictionary,
  cleanTransferDictionary
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoveAppealModalProvider);
