import React, { useState, useEffect } from "react";

import { DateInput, MaskInput, isEmpty, FormField } from "@omnichat/arm_ui_kit";

import {
  IValidationErrors,
  IValidationRule
} from "../../../Common/Interfaces/IValidation";
import { Input } from "../../../../Components";
import { validate } from "../../../Common/Utils/CommonUtils";

import * as s from "./AdditionalClientDataFields.module.scss";

interface IAdditionalClientDataFieldsProps {
  /** Сохранение полей доп. данных клиента */
  saveAdditionalClientProps?: any;
  /** Дополнительный стиль AdditionalClientDataFields */
  extraClass?: (string | object)[];
  /** Признак необходимости заполнения поля ФИО. */
  nameRequired?: boolean;
  /** Инициализационное значение ФИО. */
  nameValue?: string;
  /** Ошибка в поле ФИО. */
  nameError?: boolean;
  /** Подсказка поля ФИО. */
  nameActionText?: string;

  /** Признак необходимости заполнения телефонного поля. */
  phoneRequired?: boolean;
  /** Тема телефонного поля. */
  phoneTheme?: "default" | "error";
  /** Подсказка телефонного поля. */
  phoneActionText?: string;
  /** value компонента MaskInput. */
  phoneValue?: number;

  /** Признак необходимости заполнения поля даты. */
  dateRequired?: boolean;
  /** Инициализационное значение даты. */
  dateValue?: number;
  /** Тема поля даты. */
  dateTheme?: "default" | "error";
  /** Подсказка */
  dateActionText?: string;
}

const AdditionalClientDataFields: React.FunctionComponent<
  IAdditionalClientDataFieldsProps
> = ({
  saveAdditionalClientProps,
  nameRequired,
  nameError,
  nameActionText,
  nameValue,
  phoneRequired,
  phoneTheme,
  phoneActionText,
  phoneValue,
  dateRequired,
  dateTheme,
  dateActionText,
  dateValue
}): JSX.Element => {
  const [name, setNameInput] = useState("");
  const [date, setDateInput] = useState("");
  const [phone, setPhone] = useState<string>("");
  const [errors, setErrors] = useState<IValidationErrors>({ phone: null });

  const customValidationRules: IValidationRule[] = [
    {
      rule: (field: string) => {
        let result = true;
        const numbersOnly = field.replace(/[^\d]/g, "");

        if (field && numbersOnly && numbersOnly.length > 1) {
          result = /\+\d \(\d\d\d\) \d\d\d-\d\d-\d\d/.test(field);
        }

        return [result];
      },
      fieldName: "phone",
      errorText: "Введён невалидный номер телефона."
    }
  ];

  /**
   * Валидирует указанное поле и обновляет объект ошибок.
   *
   * @param {{[key: string]: any}} field Объект, содержащий поле для валидации.
   */
  const handleValidateField = (field: { [key: string]: any }): boolean => {
    const { errors: newErrors, isValid } = validate(
      field,
      customValidationRules
    );

    setErrors((prevState) => ({ ...prevState, ...newErrors }));

    return isValid;
  };

  const handlePhoneChange = (e: any) => {
    setPhone(e.target.value);
  };

  const handleNameChange = (value: string) => {
    setNameInput(value);
  };

  const onFIOChange = (value: string) => {
    saveAdditionalClientProps("remark_fio", value);
  };

  const onPhoneChange = (e: any) => {
    let phone = e.target.value.replace(/[^\d]/g, "");

    if (phone === 0) {
      phone = "";
    }

    if (handleValidateField({ phone: e.target.value })) {
      saveAdditionalClientProps("remark_phone", phone);
    }
  };

  const onDateChange = (e: any) => {
    const date = Date.parse(e) / 1000;
    setDateInput(e);
    saveAdditionalClientProps("remark_birthday", date + "");
  };

  const resetDate = () => {
    setDateInput("");
    saveAdditionalClientProps("remark_birthday", "");
  };

  useEffect(() => {
    nameValue && setNameInput(nameValue);
  }, [nameValue]);

  useEffect(() => {
    phoneValue && setPhone(phoneValue + "");
  }, [phoneValue]);

  useEffect(() => {
    dateValue && setDateInput(new Date(dateValue).toString());
  }, [dateValue]);

  return (
    <div className={s["additionalClientDataFieldsContainer"]}>
      <div className={s["nameContainer"]}>
        <Input
          value={name}
          isError={nameError}
          actionText={nameActionText}
          required={nameRequired}
          placeholder="Не указано"
          label="ФИО"
          onChange={handleNameChange}
          onBlur={onFIOChange}
        />
      </div>

      <div className={s["phoneContainer"]}>
        <MaskInput
          mask="+7 (999) 999-99-99"
          onChange={handlePhoneChange}
          value={phone}
          label="телефон"
          required={phoneRequired}
          theme={!isEmpty(errors.phone) ? "error" : phoneTheme}
          actionText={
            !isEmpty(errors.phone)
              ? (errors.phone as string[])[0]
              : phoneActionText
          }
          placeholder="+7 (___) ___ - __ - __"
          onBlur={onPhoneChange}
        />
      </div>

      <div className={s["dateContainer"]}>
        <FormField
          actionText={dateActionText}
          theme={dateTheme}
          label="дата рождения"
          required={dateRequired}
        >
          <DateInput
            theme={dateTheme}
            selected={date}
            onSelectDay={onDateChange}
            onResetValue={resetDate}
          />
        </FormField>
      </div>
    </div>
  );
};

export default AdditionalClientDataFields;
