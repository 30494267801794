import React from "react";

import { ScrollBox } from "@omnichat/arm_ui_kit";

import { IMessage } from "../../../Common/Interfaces/Message.interfaces";

import * as cs from "../../../../styles/common.module.scss";
import * as s from "./DialogContextModal.module.scss";

export interface IDialogContext extends IDialogContextMeta {
  messages: IMessage[];
}

export interface IDialogContextMeta {
  pageUrl: string;
  serviceName: string;
  displayHeader: string;
  clientRole: string;
}

interface IDialogContextModal extends IDialogContextMeta {
  children: JSX.Element;
}

const DialogContextModal: React.FunctionComponent<IDialogContextModal> = ({
  pageUrl,
  displayHeader,
  serviceName,
  clientRole,
  children,
}): JSX.Element => {
  return (
    <div className={cs["defaultModal"]}>
      <div className={cs["defaultModalHeader"]}>
        Контекст диалога
        <ul className={s["modalHeaderContextInfo"]}>
          {pageUrl ? <li>{`Адрес: ${pageUrl}`}</li> : null}
          {displayHeader ? <li>{`Шаг услуги: ${displayHeader}`}</li> : null}
          {serviceName ? <li>{`Название услуги: ${serviceName}`}</li> : null}
          {clientRole ? <li>{`Роль клиента: ${clientRole}`}</li> : null}
        </ul>
      </div>
      <div className={cs["defaultModalContent"]}>
        <ScrollBox autoHeight hasScrollControlBackground autoHeightMax={530}>
          <div className={s["marginScrollBoxContent"]}>{children}</div>
        </ScrollBox>
      </div>
    </div>
  );
};

export default DialogContextModal;
