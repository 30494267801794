import React from "react";
import { Icon, Avatar } from "@omnichat/arm_ui_kit";

import InfoField from "../InfoField/InfoField";
import DialogContextButton from "./DialogContextButton/DialogContextButton";
import PhoneField from "./PhoneField/PhoneField";
import AdditionalClientInfo from "./AdditionalClientInfo/AdditionalClientInfo";

import { IClientData } from "../../Interfaces/IClientData";
import { IClientLsData } from "../../Interfaces/IClientLsData";
import { IRegion } from "../../Interfaces/IRegion";

import * as s from "./ClientInfo.module.scss";

interface IClientInfoProps {
  /** Признак отображения персональных даных клиента. */
  showPersonalData?: boolean;
  /** Лицевой счёт клиента. */
  clientData: IClientData;
  /** Регион клиента. */
  region: IRegion;
  /** Скиллгруппа */
  skillGroup: string;
  /** путь до file storage */
  filesUrl: string;
  dialogContextHandle: () => void;
}

const WEBCHAT = 9;
const VIBER = 2;
const TELEGRAM = 3;
const VK = 4;

const ClientInfo: React.FunctionComponent<IClientInfoProps> = ({
  showPersonalData,
  clientData,
  region,
  filesUrl,
  skillGroup,
  dialogContextHandle = null
}): JSX.Element => {
  const mapperPersonalAccount = (lsData: IClientLsData) => {
    return (
      <div key={lsData.region}>
        <span>{lsData.region}:</span>
        <span>{lsData.number}</span>
      </div>
    );
  };

  const getVkLink = (login: string) => {
    const vkId = login.replace("vk_", "");
    return `https://vk.com/id${vkId}`;
  };

  return (
    <div className={s["container"]}>
      {showPersonalData && (
        <div className={s["personalData"]}>
          <div className={s["avatar"]}>
            <Avatar
              extraClass={[s["avatarIcon"]]}
              url={clientData.photo && filesUrl + clientData.photo}
              size="large"
            />
          </div>

          {clientData.name && (
            <div className={s["name"]}>
              <span>{clientData.name}</span>
            </div>
          )}

          {clientData.phone && <PhoneField value={clientData.phone} />}

          {clientData.channel?.id === VK && (
            <a className={s["vkLink"]} href={getVkLink(clientData.login)}>
              {getVkLink(clientData.login)}
            </a>
          )}

          {region && region.name && (
            <div className={s["location"]}>
              <Icon
                extraClass={[s["icon"]]}
                name="place"
                color="light-gray"
                width="1em"
                height="1em"
              />
              <span>{region.name}</span>
            </div>
          )}

          {clientData.online ? (
            <div className={s["status"]}>
              <span>Online</span>
            </div>
          ) : (
            <div className={s["status"]}>
              <span>Offline</span>
            </div>
          )}
        </div>
      )}

      <div className={s["otherData"]}>
        {clientData.name && !showPersonalData && (
          <div className={s["name"]}>
            <span>{clientData.name}</span>
          </div>
        )}

        {dialogContextHandle && (
          <DialogContextButton onClick={dialogContextHandle} />
        )}

        {skillGroup && (
          <InfoField label="Группа">
            <span>{skillGroup}</span>
          </InfoField>
        )}

        {clientData.channel && clientData.channel.name && (
          <InfoField label="Канал">
            <span>{clientData.channel.name}</span>
          </InfoField>
        )}

        {clientData.mrf && clientData.mrf.name && (
          <InfoField label="МРФ">
            <span>{clientData.mrf.name}</span>
          </InfoField>
        )}

        {clientData.login && (
          <InfoField label="Логин">
            <span>{clientData.login}</span>
          </InfoField>
        )}

        {clientData.userData && clientData.userData.ls && (
          <InfoField label="Лицевые счета">
            {Object.values(clientData.userData.ls).map(mapperPersonalAccount)}
          </InfoField>
        )}
        {clientData &&
          clientData.userData &&
          clientData.userData.additionalClientData && (
            <AdditionalClientInfo
              data={
                clientData &&
                clientData.userData &&
                clientData.userData.additionalClientData
              }
            />
          )}
      </div>
    </div>
  );
};

export default ClientInfo;
