import React, { Component } from "react";

import { Button, ScrollBox } from "@omnichat/arm_ui_kit";

import styled from "styled-components";
import { connect } from "react-redux";
import { getChannelsData } from "./Store/Channels.actions";
import { NAMESPACE, CHANNELS_DATA_STATE_KEY } from "./consts";

import { Input } from "../../Components";
import { CreateChannel } from "./Components/CreateChannel";
import { EditChannel } from "./Components/EditChannel";
import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit";
import * as s from "./styles.module.scss";

const Root = styled.div`
  font-size: 10px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const Header = styled.div`
  border-bottom: 1px #cbd7da solid;
  padding: 0 0 12px;
  line-height: 34px;
  font-size: 1.125em;
  color: #525f67;
  white-space: nowrap;
  font-weight: 700;
  font-size: 16px;
`;

const Content = styled.div`
  font-size: inherit;
  display: flex;
  padding-top: 10px;
  height: 100%;
`;

const ContentLeft = styled.div`
  font-size: inherit;
  display: flex;
  flex-direction: column;
  height: calc(100% - 62px);
`;

const ScrollContentPadding = styled.div`
  padding-right: 20px;
  height: 100%;
`;

const ContentLeftAddChannelType = styled.div`
  font-size: inherit;
  display: flex;
  flex-direction: column;
`;

const ContentLeftListChannelTypes = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentRight = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 20px;
  position: relative;
`;

const ChannelTypesHeader = styled.div`
  font-size: 16px;
  border-bottom: 1px solid #cbd7da;
  margin-bottom: 10px;
  color: #829199;
  font-weight: 700;
  line-height: 34px;
`;
const ChannelsTypeList = styled.div`
  display: flex;
  flex-direction: column;
`;
const ChannelType = styled.div<{ selected: boolean }>`
  font-size: 16px;
  display: block;
  background: ${(props) => (props.selected ? "#42abe1" : "#ffffff")};
  border-radius: 3px;
  padding: 10px;
  color: ${(props) => (props.selected ? "#ffffff" : "#0090db")};
  cursor: pointer;
  &:hover {
    background: ${(props) => (props.selected ? "#0090db" : "#e4f1f7")};
  }
`;

const ChannelList = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 62px);
`;

const Channel = styled.div`
  display: flex;
  text-decoration: none;
  background: #fff;
  border-radius: 3px;
  padding: 10px;
  color: #0090db;
  cursor: pointer;
  font-size: 16px;
  border-bottom: 1px #e9eeef solid;
  &:hover {
    background: rgb(228, 241, 247);
  }
`;

const channelTypes = [
  {
    id: 9,
    name: "Веб чат"
  },
  {
    id: 10,
    name: "Мобильное приложение"
  }
];

/**
 * Интерфейс собственных свойств компонента.
 *
 * @prop {Array<{ id: number; name: string; channelTypeId: number }>} [channels] Массив с каналами.
 */
interface IOwnProps {
  channels?: Array<{ id: number; name: string; channelTypeId: number }>;
}

/**
 * Интерфейс экшенов.
 */
interface IDispatchProps {
  getChannelsData: ActionCreatorWithoutPayload;
}

interface IState {
  channelType?: number;
  createChannel: boolean;
  editChannelId?: number;
  query?: string;
}

type TProps = IOwnProps & IDispatchProps;

class ChannelsProvider extends Component<TProps, IState> {
  state = {
    channelType: 9,
    createChannel: false,
    editChannelId: null,
    query: ""
  };

  componentDidMount() {
    this.props.getChannelsData();
    setTimeout(() => {
      this.handleOpenChannels(9);
    }, 0);
  }

  onSearchChannel = (value: string) => {
    this.setState({ query: value });
  };

  handleOpenChannels = (channelType: number) =>
    this.setState({ channelType, query: "" });

  handleCreateChannel = () => this.setState({ createChannel: true });

  handleEditChannel = (cnannel) => this.setState({ editChannelId: cnannel.id });

  CreateChannel = () => {
    const handleClose = () => {
      this.setState({ createChannel: false });
      this.props.getChannelsData();
    };

    return (
      <CreateChannel
        open={this.state.createChannel}
        handleClose={handleClose}
      />
    );
  };

  EditChannel = () => {
    const handleClose = () => {
      this.setState({ editChannelId: null });
      this.props.getChannelsData();
    };

    if (!this.state.editChannelId) {
      return null;
    }

    return (
      <EditChannel
        open={this.state.editChannelId !== null}
        channel={this.state.editChannelId}
        handleClose={handleClose}
      />
    );
  };

  render() {
    return (
      <Root>
        {this.CreateChannel()}

        {this.EditChannel()}

        <Header>Управление каналами</Header>

        <Content>
          <ContentLeft>
            <ScrollBox
              autoHeight
              autoHeightMax={600}
              hasScrollControlBackground
            >
              <ScrollContentPadding>
                <ContentLeftAddChannelType>
                  <Button
                    type="bare"
                    theme="block"
                    iconName="plus_bold"
                    iconPosition="right"
                    iconColor="blue"
                    onClick={this.handleCreateChannel}
                    text="Добавить канал"
                  />
                </ContentLeftAddChannelType>

                <ContentLeftListChannelTypes>
                  <ChannelTypesHeader>ТИП КАНАЛА</ChannelTypesHeader>
                </ContentLeftListChannelTypes>

                <ChannelsTypeList>
                  {channelTypes.map((el) => (
                    <ChannelType
                      key={el.id}
                      selected={this.state.channelType === el.id}
                      onClick={() => this.handleOpenChannels(el.id)}
                    >
                      {el.name}
                    </ChannelType>
                  ))}
                </ChannelsTypeList>
              </ScrollContentPadding>
            </ScrollBox>
          </ContentLeft>

          <ContentRight>
            <div className={s["listHeader"]}>
              <div className={s["listHeaderBtnWrap"]}>
                <h4 className={s["listTitle"]}>
                  {
                    channelTypes.filter(
                      (el) => el.id === this.state.channelType
                    )[0].name
                  }
                </h4>
              </div>
              <Input
                placeholder="Поиск"
                onChange={this.onSearchChannel}
                value={this.state.query}
              />
              <div className={s["listControl"]}>
                <div className={s["listCol1"]}>id</div>
                <div className={s["listCol2"]}>Название</div>
              </div>
            </div>
            <ChannelList>
              <ScrollBox
                autoHeight
                autoHeightMax={600}
                hasScrollControlBackground
              >
                <ScrollContentPadding style={{ paddingTop: "140px" }}>
                  {!!this.props.channels &&
                    this.props.channels
                      .filter((el) => {
                        const reg = new RegExp(this.state.query.toLowerCase());

                        return (
                          reg.test(el.name.toLowerCase()) &&
                          el.channelTypeId === this.state.channelType
                        );
                      })
                      .map((el) => (
                        <Channel
                          onClick={() => this.handleEditChannel(el)}
                          key={el.id}
                        >
                          <div className={s["listCol1"]}>{el.id}</div>
                          <div className={s["listCol2"]}>{el.name}</div>
                        </Channel>
                      ))}
                </ScrollContentPadding>
              </ScrollBox>
            </ChannelList>
          </ContentRight>
        </Content>
      </Root>
    );
  }
}

export default connect(
  (state) => ({
    channels: state[NAMESPACE][CHANNELS_DATA_STATE_KEY]?.data
  }),
  { getChannelsData }
)(ChannelsProvider);
