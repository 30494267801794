import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { sendTransferData } from "../Actions/TransferAppealModal.actionCreators";
import MoveAppealModalProvider from "./MoveAppealModal.provider";

/**
 * Модель свойств, полученных из store.
 *
 * @prop {IAsyncData} asyncAvailableHotKeysData Контейнер с данными МО трансфера,
 * которые получены асинхронно.
 */
interface IStateProps {}

/**
 * Экшены.
 */
interface IDispatchProps {
  sendTransferData: any;
}

/**
 * Интерфейс собственных свойств компонента.
 *
 * @prop {number} appealId Идентификатор обращения.
 * @prop {Function} close Колбэк на закрытие модального окна.
 * @prop {Function} postAction Колбэк для выполнения после нажатия на "Submit".
 */
interface IOwnProps {
  appealId: string;
  close: () => void;
  postAction?: () => void;
}

type TProps = IOwnProps & IDispatchProps & IStateProps;

class TransferAppealModalProvider extends PureComponent<TProps> {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      target: null,
      modalOpen: false
    };
  }

  /**
   * Обработка нажатия на кнопку "Продолжить".
   * @param data
   */
  handleConfirm = (data: any) => {
    this.props.sendTransferData(data);
    this.props.postAction?.();
  };

  render() {
    const { appealId, close } = this.props;

    return (
      <MoveAppealModalProvider
        close={close}
        appealId={appealId}
        header="Передать обращение?"
        onConfirm={this.handleConfirm}
      />
    );
  }
}

const mapDispatchToProps = {
  sendTransferData
};

export default connect(null, mapDispatchToProps)(TransferAppealModalProvider);
