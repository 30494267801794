import { takeEvery, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import Informer from "../../../arm/Wrappers/Informer";
import { actionUsersProfileModal as actions } from "../Store/UsersProfileModal.reducer";
import { DTO } from "../UsersProfileModal.d";

interface ProfileData {
  close: Function;
  formData: DTO;
}

export function* sendUsersProfileSaga({ payload }: PayloadAction<ProfileData>) {
  yield put(actions.setSendDataHandling(true));
  const { close: closeModal, formData } = payload;

  try {
    const { id } = formData;
    const method = id ? "PUT" : "POST";
    const path = id ? `bff/settings/profiles/${id}/` : `bff/settings/profiles/`;
    const informerMessage = id
      ? "Пользователь был отредактирован"
      : "Пользователь был создан";

    const res = yield httpRequest(method, path, {
      body: { ...formData, administratorId: +formData.administratorId }
    });

    if (res?.success) {
      const info = new Informer(informerMessage);
      info.show();
      yield put(actions.resetUsersProfileData());
      closeModal();
    } else {
      const info = new Informer("Не удалось отредактировать профиль");
      info.show();
      yield put(
        actions.setErrorMesssage("Проверьте правильность заполненных полей")
      );

      // todo доделать ошибку о занятом email - нужны правки на бэке
      // yield put(
      //   setErrorMesssage(
      //     "Данная почта уже используется другим пользователем"
      //   )
      // );
    }
  } catch ($error) {
    console.error($error);
  } finally {
    yield put(actions.setSendDataHandling(false));
  }
}

export default function*() {
  yield takeEvery([actions.sendUsersProfileData], sendUsersProfileSaga);
}
