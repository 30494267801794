import * as React from "react";

import DialogContextModal from "../../ReactFeatures/DialogHeader/Components/DialogContextModal/DialogContextModal";
import MessagesBox from "../../ReactFeatures/MessageBox";

import Modal from "../Wrappers/Modal";

const openDialogContext = (filesUrl, contextData, onShowImageViewer) => {
  const modalId = "DialogContextModal";
  const modal = new Modal(modalId, 900, () => (
    <DialogContextModal
      pageUrl={contextData?.pageUrl}
      serviceName={contextData?.serviceName}
      displayHeader={contextData?.displayHeader}
      clientRole={contextData?.clientRole}
    >
      <MessagesBox
        messages={contextData?.messages}
        filesUrl={filesUrl}
        avatarUrl={CROP_IMG_URL}
        onShowImageViewer={onShowImageViewer}
      />
    </DialogContextModal>
  ));
  modal.open();
};

export default openDialogContext;
