import React, { FC, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { isEmpty, Preloader } from "@omnichat/arm_ui_kit";
import useQuery from "../../ReactFeatures/Common/Hooks/useQuery";
import { DashboardQueryParams } from "../DashboardSection/DashboardSection";

import * as selector from "./Store/Monitoring.selector";
import { useActions } from "./Store/Monitoring.reducer";

import { ModalWindow } from "../../Components";
import {
  SectionGroups,
  SkillGroupItem,
  AgentList,
  AgentListHeader
} from "./Components";
import SkillGroupCapacityModalProvider from "../../ReactFeatures/SkillGroupCapacityModal/skillGroupCapacityModal.provider";
import AgentCapacityModal from "../../ReactFeatures/AgentCapacityModal";
import { useDidUptateByDelayedSendQuery } from "../../ReactFeatures/Common/Hooks/useDelayedSendQuery";

enum Modals {
  "skillgroupLoad",
  "userLoad"
}
const manageLoad = [Modals.skillgroupLoad, Modals.userLoad];

type Props = {
  onUserSelect: (id: number) => void;
  onGroupSelect: (id?: number) => void;
};

const MonitoringProvider: FC<Props> = ({ onGroupSelect, onUserSelect }) => {
  const [activeModal, toggleModal] = useState<Modals>(null);

  const {
    query: { skill, agent }
  } = useQuery<DashboardQueryParams>();

  const agentAction = useActions();

  const currentSkillGroupId = skill ? +skill : null;
  const currentAgentId = agent ? +agent : null;

  const isLoading = useSelector(selector.loadingStatus);
  const { editableAgents, editableSkills } = useSelector(selector.agentsState);
  const commonActiveCounter = useSelector(selector.commonActiveCounter);
  const agents = useSelector(
    selector.agentsWithMonitoring(currentSkillGroupId)
  );
  const skillGroups = useSelector(selector.skillGroupsWithMonitoring);
  const editedUserId = useSelector(selector.agentData);
  const editedSkillGroupId = useSelector(selector.skillGroupData);
  const query = useSelector(selector.getQuery);
  const currentAgentsPage = useSelector(selector.getPageAgents);

  const getAgentList = (agentPage: number) => {
    if (!currentSkillGroupId) return;
    agentAction.getAgentListAction({
      page: agentPage,
      query: query,
      skill_id: currentSkillGroupId
    });
  };

  useEffect(() => {
    const userId = core.user.id;
    const userHash = core.user.hash;

    agentAction.getSkillGroupListAction();
    agentAction.setQuery("");

    agentAction.connectRealtimeWs({
      userId,
      userHash,
      url: `wss://${location.host}/workspace/chat`,
      socketPrefix: "MONITORING_WS_"
    });

    return () => {
      agentAction.startDashboard();
      agentAction.disconnectRealtimeWs();
    };
  }, []);

  useEffect(() => {
    if (!currentAgentsPage) return;
    getAgentList(currentAgentsPage);
  }, [currentAgentsPage]);

  useEffect(() => {
    getAgentList(0);
  }, [currentSkillGroupId, query]);

  const handleSelectAgents = (id: number) => {
    onUserSelect(id);
  };

  const onSearch = (v: string) => {
    agentAction.resetAgentPage();
    agentAction.setQuery(v);
  };

  const { inputText, onInputQuery } = useDidUptateByDelayedSendQuery({
    cb: onSearch
  });

  const onSelectSkillGroup = (id: number) => {
    onGroupSelect(id);
  };

  const onSelectHeaderGroupAgents = () => {
    onSelectSkillGroup(currentSkillGroupId);
  };

  const onNextPageAgents = () => {
    if (currentSkillGroupId) {
      agentAction.changeAgentPage(currentAgentsPage + 1);
    }
  };

  const onHideAgents = () => {
    onGroupSelect();
    onInputQuery("");
    agentAction.resetAgentPage();
    agentAction.resetCurrentAgent();
    agentAction.resetCurrentSkillGroup();
  };

  const onManageLoadAgent = (id?: number) => {
    toggleModal(Modals.userLoad);
    agentAction.setAgentEdited(id);
  };

  const onManageLoadSkillgroup = (id?: number) => {
    toggleModal(Modals.skillgroupLoad);
    agentAction.setSkillGroupEdited(id);
  };

  const closeModal = () => toggleModal(null);

  const onCloseManageSkillgroup = () => {
    closeModal();
    agentAction.cleanSkillGroupEdited();
  };

  const onConfirmManageSkillgroup = () => {
    onCloseManageSkillgroup();
    agentAction.getSkillGroupListAction();
  };

  const onCloseManageAgent = () => {
    closeModal();
    agentAction.cleanAgentEdited();
  };

  const onConfirmManageAgent = () => {
    onCloseManageAgent();
    getAgentList(currentAgentsPage);
  };

  const closeModals = {
    [Modals.skillgroupLoad]: onCloseManageSkillgroup,
    [Modals.userLoad]: onCloseManageAgent
  };

  return (
    <>
      <SectionGroups title="Мониторинг нагрузки" onNextPage={onNextPageAgents}>
        <Preloader
          size="mini"
          show={!isEmpty(isLoading) && isEmpty(skillGroups)}
        >
          <>
            {!currentSkillGroupId && (
              <>
                <SkillGroupItem
                  skillgroup={{
                    name: `Все группы`,
                    agentsCount: commonActiveCounter.agentsCount,
                    appealsCount: commonActiveCounter.appealsCount
                  }}
                  current={!currentSkillGroupId}
                />
                {skillGroups.map((group, i) => (
                  <SkillGroupItem
                    key={`${i}_${group.id}`}
                    skillgroup={group}
                    onSelect={onSelectSkillGroup}
                    onManageLoad={onManageLoadSkillgroup}
                    current={currentSkillGroupId === group.id}
                    editable={editableSkills}
                  />
                ))}
              </>
            )}

            {currentSkillGroupId && !isEmpty(skillGroups) && (
              <>
                <AgentListHeader
                  onGoBack={onHideAgents}
                  onInputQuery={onInputQuery}
                  query={inputText}
                />

                {skillGroups.find((g) => g.id === currentSkillGroupId) && (
                  <SkillGroupItem
                    skillgroup={skillGroups.find(
                      (g) => g.id === currentSkillGroupId
                    )}
                    onSelect={onSelectHeaderGroupAgents}
                    onManageLoad={onManageLoadSkillgroup}
                    current={!currentAgentId}
                    editable={editableSkills}
                  />
                )}

                <Preloader
                  size="mini"
                  show={!isEmpty(isLoading) && isEmpty(agents)}
                >
                  <AgentList
                    agents={agents}
                    onManageLoad={onManageLoadAgent}
                    hasQuery={!!query}
                    onSelect={handleSelectAgents}
                    currentAgentId={currentAgentId}
                    editable={editableAgents}
                  />
                </Preloader>
              </>
            )}
          </>
        </Preloader>
      </SectionGroups>

      <ModalWindow
        open={manageLoad.includes(activeModal)}
        onCloseModal={closeModals[activeModal]}
        isButtonClose
      >
        <>
          {activeModal === Modals.skillgroupLoad && (
            <SkillGroupCapacityModalProvider
              close={onConfirmManageSkillgroup}
              skillId={editedSkillGroupId}
              skillGroupName={
                skillGroups.find(({ id }) => id === editedSkillGroupId).name
              }
            />
          )}
          {activeModal === Modals.userLoad && (
            <AgentCapacityModal
              close={onConfirmManageAgent}
              agentId={editedUserId}
            />
          )}
        </>
      </ModalWindow>
    </>
  );
};

export default MonitoringProvider;
