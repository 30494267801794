import React, { FC } from "react";

import { ScrollBox, Button as OldButton } from "@omnichat/arm_ui_kit";

import AppealTimer from "../../../Common/Components/AppealTimer/AppealTimer";
import ClientInfo from "../../../Common/Components/ClientInfo/ClientInfo";
import InfoField from "../../../Common/Components/InfoField/InfoField";
import { ISkill } from "../../../Common/Interfaces/SkillGroup.interfaces";

import CsiInfo from "../../../Common/Components/CsiInfo/CsiInfo";
import { IAppealData } from "../../../Common/Interfaces/IAppealData";

import * as s from "./AppealInfo.module.scss";
import c from "classnames";

interface IModalsHandler {
  enable: boolean;
  handler: () => void;
}

/**
 * Интерфейс собственных свойств компонента.
 *
 * @prop {JSX.Element} messagesBox Блок с сообщениями.
 */
interface IAppealInfoProps {
  appealData: IAppealData;
  additionalClientFields: () => JSX.Element;
  filesUrl: string;
  reopenAppeal?: IModalsHandler;
  closeAppeal?: IModalsHandler;
  transferAppeal?: IModalsHandler;
  onConfirmAction?: () => void;
  thematicsConstructor?: () => JSX.Element;
  messagesBox?: JSX.Element;
  dialogContextHandle: () => void;
  onShowImageViewer?: (imageSrc: string) => void;
  open?: boolean;
  avatarUrl?: string;
  onBack: () => void;
}

const AppealInfo: FC<IAppealInfoProps> = ({
  appealData,
  filesUrl,
  reopenAppeal,
  closeAppeal,
  transferAppeal,
  thematicsConstructor,
  additionalClientFields,
  messagesBox,
  dialogContextHandle = null,
  onBack
}): JSX.Element => {
  const groupsMapper = (skill: ISkill): JSX.Element => {
    if (!skill?.id && !skill?.name) return;

    return (
      <div key={`skill-${skill.id}`}>
        <div>{skill.name}</div>
      </div>
    );
  };

  return (
    <div id="AppealInfo" className={s["container"]}>
      <div className={c(s["header"])}>
        <div className={c(s["headerAppealMain"])}>
          <div className={s["headerAppealMainTitle"]}>
            Обращение #{appealData.id}
          </div>
          <AppealTimer
            time={appealData.operationTime}
            tooltipText="Общее время работы с обращением"
          />
        </div>

        <div className={s["headerControlBtns"]}>
          <div>
            {closeAppeal?.enable && (
              <OldButton
                key="closeAppeal"
                type="bare"
                theme="default"
                iconName="cross"
                iconColor="blue"
                text="Закрыть"
                onClick={closeAppeal.handler}
              />
            )}
            {transferAppeal?.enable && (
              <OldButton
                key="transferAppeal"
                type="bare"
                theme="default"
                iconName="repeat"
                iconColor="blue"
                text="Передать"
                onClick={transferAppeal.handler}
              />
            )}
            {reopenAppeal?.enable && (
              <OldButton
                key="reopenAppeal"
                type="bare"
                theme="default"
                iconName="replay"
                iconColor="blue"
                text="Открыть"
                onClick={reopenAppeal.handler}
              />
            )}
          </div>

          <div>
            <OldButton
              key="back"
              type="bare"
              theme="default"
              text="Назад"
              onClick={onBack}
            />
          </div>
        </div>
      </div>

      <div className={c(s["content"])}>
        <div>
          <ScrollBox autoHeight hasScrollControlBackground autoHeightMax={530}>
            <div className={c(s["marginScrollBoxContent"])}>{messagesBox}</div>
          </ScrollBox>
        </div>

        <div>
          <ScrollBox autoHeight hasScrollControlBackground autoHeightMax={530}>
            <div className={c(s["marginScrollBoxContent"])}>
              <ClientInfo
                showPersonalData
                clientData={appealData.client}
                region={appealData.region}
                filesUrl={filesUrl}
                skillGroup={appealData.skillGroup.name}
                dialogContextHandle={dialogContextHandle}
              />
              {additionalClientFields && additionalClientFields()}
              <div className={s["contentAppealInfoMain"]}>
                <InfoField key="results" label="Итоги обращения">
                  <></>
                </InfoField>

                <InfoField key="sl" label="SL">
                  <span>{appealData.sl ? "Выполнен" : "Не выполнен"}</span>
                </InfoField>

                {!!appealData.skillsOfAppeal && (
                  <InfoField key="skills" label="Группы">
                    {appealData.skillsOfAppeal.map(groupsMapper)}
                  </InfoField>
                )}
              </div>

              {thematicsConstructor && (
                <div className={s["contentAppealInfoThematics"]}>
                  {thematicsConstructor()}
                </div>
              )}

              {!!appealData.comment && (
                <>
                  <hr className={c(s["contentHr"])}></hr>

                  <InfoField key="comment" label="Комментарий">
                    <span>{appealData.comment}</span>
                  </InfoField>
                </>
              )}

              {!!appealData.csi && (
                <div className={s["contentAppealInfoCsi"]}>
                  <hr className={s["contentHr"]}></hr>

                  <CsiInfo
                    rate={appealData.csi.rate}
                    comment={appealData.csi.comment}
                    categories={appealData.csi.categories}
                  />
                </div>
              )}
            </div>
          </ScrollBox>
        </div>
      </div>
    </div>
  );
};

export default AppealInfo;
