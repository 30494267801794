import React, { FC, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useQuery from "../Common/Hooks/useQuery";
import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload
} from "@reduxjs/toolkit";
import { Preloader, isEmpty } from "@omnichat/arm_ui_kit";

import * as appealInfoSelectors from "./AppealInfo.selectors";
import { AdditionalFields } from "../AdditionalFields";
import { StoreState } from "../../ReactCore/store/types";
import AppealInfo from "./Components/AppealInfo/AppealInfo";
import { cleanAppealData } from "./Reducers/AppealInfo.reducer";
import { getAppealData } from "./Actions/AppealInfo.actionCreators";
import { IDialogContext } from "../DialogHeader/Components/DialogContextModal/DialogContextModal";
import { TransferAppealModal, ReopenAppealModal } from "../MoveAppealModal";
import ThematicInfoPanel from "../ThematicModal/Providers/ThematicInfoPanel.provider";
import { CloseDialogModal } from "../CloseDialogModal";
import MessagesBox from "../MessageBox/Components/MessagesBox";
import openDialogContext from "../../arm/Constructors/openDialogContext";
import { ModalWindow } from "../../Components";
import Modal from "../../arm/Wrappers/Modal";
import { parseParamsToUrl } from "../../Dashboard/DashboardSection/utils";
import { DashboardQueryParams } from "../../Dashboard/DashboardSection/DashboardSection";

type TUserRights = "appeal_close" | "appeal_transfer" | "appeal_reopen";
export const UserRights = {
  closeAppeal: "appeal_close" as TUserRights,
  transferAppeal: "appeal_transfer" as TUserRights,
  reopenAppeal: "appeal_reopen" as TUserRights
};

enum ApealOpenStates {
  Closed,
  Opened,
  Waiting
}

enum BotStates {
  NotActive,
  Active,
  Queued,
  QueuedAndGreeted,
  TransferedToAgent,
  NotProcessed,
  Prompted
}

enum Modals {
  ReopenAppealModal = 1,
  TransferAppealModal = 2,
  CloseDialogModal = 3
}

/**
 * Интерфейс свойств полученных из store.
 */
interface IStoreProps {
  dialogId: number;
  appealData: any;
  clientId: number;
  channelId: number;
  channelType: number;
  remarkFio: string;
  remarkPhone: string;
  birthDay: any;
  contextData: IDialogContext;
}

/**
 * Интерфейс экшенов.
 */
interface IDispatchProps {
  cleanAppealData: ActionCreatorWithoutPayload;
  getAppealData: ActionCreatorWithPayload<
    { appealId: string; onError?: () => void },
    string
  >;
}

type TProps = IStoreProps & IDispatchProps;

const AppealInfoProvider: FC<TProps> = ({
  appealData,
  birthDay,
  channelId,
  channelType,
  cleanAppealData,
  clientId,
  contextData,
  dialogId,
  getAppealData,
  remarkFio,
  remarkPhone
}) => {
  const [activeModal, setActiveModal] = useState<Modals | null>(null);
  const { query } = useQuery<DashboardQueryParams>();
  const navigate = useNavigate();
  const { currentTab, appealId } = useParams();

  useEffect(() => {
    if (appealId) {
      getAppealData({
        appealId,
        onError: onClose
      });
    }

    return () => {
      cleanAppealData();
    };
  }, [appealId]);

  const onClose = () => {
    navigate({
      pathname: `/${currentTab}`,
      search: parseParamsToUrl(query)
    });
  };

  const transferAppealHandler = (
    action: "transfer" | "reopen",
    appealId: string
  ) => {
    const modalId = "TransferAppealModalWrap";
    const modal = new Modal(modalId, 550, (close) => {
      const modalProps = {
        close: () => {
          close();
          onClose?.();
          location.reload();
        },
        appealId: appealId
      };
      return (
        <div style={{ padding: "25px" }}>
          {action === "transfer" ? (
            <TransferAppealModal {...modalProps} />
          ) : (
            <ReopenAppealModal {...modalProps} />
          )}
        </div>
      );
    });
    modal.open();
  };

  /**
   * Обработчик отображения компонента для просмотра изображения.
   *
   * @param {string} imageSrc Ссылка на изображение.
   */
  const handleShowImageViewer = (imageSrc: string) => {
    window.imageViewer?.handleShowImage(imageSrc, 115);
  };

  const isClosedLastAppeal = (data: {
    isLast: any;
    isOpen: ApealOpenStates;
  }) => {
    return data.isLast && data.isOpen == ApealOpenStates.Closed;
  };

  const isOpenedAppealAndInActiveBot = (data: {
    isOpen: ApealOpenStates;
    botState: BotStates;
  }) => {
    return (
      data.isOpen !== ApealOpenStates.Closed &&
      data.botState !== BotStates.Active
    );
  };

  const dialogContextHandle = () => {
    openDialogContext(core.fileStorage, contextData, handleShowImageViewer);
  };

  const closeCurrentAndParentModals = () => {
    setActiveModal(null);
    onClose();
    location.reload();
  };

  return (
    <>
      <Preloader show={!appealData}>
        {appealData && (
          <>
            <AppealInfo
              additionalClientFields={() => (
                <AdditionalFields
                  channelId={channelId}
                  channelType={channelType}
                  clientId={clientId}
                  remarkFio={remarkFio}
                  remarkPhone={remarkPhone}
                  birthDay={birthDay}
                />
              )}
              onBack={onClose}
              open={true}
              appealData={appealData}
              filesUrl={core.fileStorage}
              avatarUrl={CROP_IMG_URL}
              thematicsConstructor={() => (
                <ThematicInfoPanel appealId={appealId} />
              )}
              onShowImageViewer={handleShowImageViewer}
              reopenAppeal={{
                enable:
                  isClosedLastAppeal(appealData) &&
                  core.userRights.includes(UserRights.reopenAppeal),
                // handler: () => setActiveModal(Modals.ReopenAppealModal)
                handler: () => transferAppealHandler("reopen", appealId)
              }}
              transferAppeal={{
                enable:
                  isOpenedAppealAndInActiveBot(appealData) &&
                  core.userRights.includes(UserRights.transferAppeal),
                // handler: () => setActiveModal(Modals.TransferAppealModal)
                handler: () => transferAppealHandler("transfer", appealId)
              }}
              closeAppeal={{
                enable:
                  isOpenedAppealAndInActiveBot(appealData) &&
                  core.userRights.includes(UserRights.closeAppeal),
                handler: () => setActiveModal(Modals.CloseDialogModal)
              }}
              dialogContextHandle={!isEmpty(contextData) && dialogContextHandle}
              messagesBox={
                <MessagesBox
                  messages={appealData.messages}
                  filesUrl={core.fileStorage}
                  avatarUrl={CROP_IMG_URL}
                  onShowImageViewer={handleShowImageViewer}
                  isExpanded
                />
              }
            />
            <ModalWindow
              open={!!activeModal}
              onCloseModal={() => setActiveModal(null)}
              isButtonClose
            >
              <>
                {/*{activeModal === Modals.ReopenAppealModal && (
                  <ReopenAppealModal
                    close={closeCurrentAndParentModals}
                    appealId={appealId}
                  />
                )}
                 {activeModal === Modals.TransferAppealModal && (
                  <TransferAppealModal
                    close={closeCurrentAndParentModals}
                    appealId={appealId}
                  />
                )} */}
                {activeModal === Modals.CloseDialogModal && (
                  <CloseDialogModal
                    isChat={false}
                    activeDialog={dialogId}
                    onConfirmCloseAppeal={() => {
                      closeCurrentAndParentModals();
                    }}
                    appealId={appealId}
                    onCancel={() => setActiveModal(null)}
                  />
                )}
              </>
            </ModalWindow>
          </>
        )}
      </Preloader>
    </>
  );
};

const mapStateToProps = (state: StoreState.State): IStoreProps => ({
  appealData: appealInfoSelectors.getAppealData(state),
  dialogId: appealInfoSelectors.getDialogId(state),
  clientId: appealInfoSelectors.getClientId(state),
  channelId: appealInfoSelectors.getChannelId(state),
  channelType: appealInfoSelectors.getChannelTypeId(state),
  remarkFio: appealInfoSelectors.getClientRemarkFio(state),
  remarkPhone: appealInfoSelectors.getClientRemarkPhone(state),
  birthDay: appealInfoSelectors.getClientBirthDay(state),
  contextData: appealInfoSelectors.getContextData(state)
});

const mapDispatchToProps: IDispatchProps = {
  cleanAppealData,
  getAppealData
};

export default connect(mapStateToProps, mapDispatchToProps)(AppealInfoProvider);
