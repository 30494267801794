import { takeEvery, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { httpRequest } from "../../../ReactCore/shared/httpRequest";
import { actionAgents } from "../Store/Monitoring.reducer";

function* getSkillGroupsSettingsSaga({ type }: PayloadAction) {
  yield put(actionAgents.startLoading(type));

  const query = { page: 0, size: 100500, sort: "name" };

  try {
    const res = yield httpRequest("GET", "bff/settings/skill-groups", {
      query
    });

    if (res.success) {
      yield put(actionAgents.setSkillGroupsList(res.data.content));
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put(actionAgents.finishLoading(type));
  }
}

export default function*() {
  yield takeEvery(
    actionAgents.getSkillGroupListAction,
    getSkillGroupsSettingsSaga
  );
}
