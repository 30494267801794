import React, { FC, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Preloader } from "@omnichat/arm_ui_kit";

import * as selector from "./Store/Users.selector";
import { useActions } from "./Store/Users.reducer";

import SettingsSection from "../../ReactFeatures/Common/Components/SettingsSection/SettingsSection";
import { ModalWindow, Loading } from "../../Components";
import SkillGroupModal from "../../ReactFeatures/SkillGroupModal";
import RestorePassword from "./RestorePasswordModal";
import UsersProfileModal from "../../ReactFeatures/UsersProfileModal";
import UsersMultiProfileModal from "../../ReactFeatures/UsersMultiProfileModal";

import { GroupList, UserList, ConfirmModal } from "./Components";
import { ITargetGroupUsersAction } from "./UserGroupsManager.d";
import { useDidUptateByDelayedSendQuery } from "../../../src/ReactFeatures/Common/Hooks/useDelayedSendQuery";

enum Modals {
  "editUser" = 1,
  "editSkillgroup" = 2,
  "deleteSkillgroup" = 3,
  "editCommonParams" = 4,
  "passRestore" = 5,
  "usersToArchive" = 6
}

const UsersProvider: FC = () => {
  const [activeModal, toggleModal] = useState<Modals>(null);

  const userAction = useActions();

  const users = useSelector(selector.userList);
  const skillGroups = useSelector(selector.skillGroupList);
  const editedUserId = useSelector(selector.userData);
  const editedSkillGroupId = useSelector(selector.skillGroupData);
  const currentSkillGroup = useSelector(selector.currentSkillGroup);
  const query = useSelector(selector.getQuery);
  const currentUserPage = useSelector(selector.getPageUsers);
  const currentSkillPage = useSelector(selector.getPageSkillgroups);
  const checkedUsers = useSelector(selector.getSelectedUsers);
  const allCheckBoxValue = useSelector(selector.getAllCheckboxValue);
  const restorePasswordHandling = useSelector(
    selector.getRestorePasswordHandling
  );

  const getUserList = (userPage: number) => {
    if (!currentSkillGroup?.id) return;
    userAction.getUserListAction({
      page: userPage,
      query: query,
      skill_id: currentSkillGroup.id
    });
  };

  const getSkillGroupList = (groupPage: number) =>
    userAction.getSkillGroupListAction({
      page: groupPage
    });

  useEffect(
    () => () => {
      userAction.startUserManager();
    },
    []
  );

  useEffect(() => {
    getSkillGroupList(currentSkillPage);
  }, [currentSkillPage]);

  useEffect(() => {
    if (!currentUserPage) return;
    getUserList(currentUserPage);
  }, [currentUserPage]);

  useEffect(() => {
    getUserList(0);
  }, [currentSkillGroup?.id, query]);

  const {
    inputText: searchAgentText,
    onInputQuery: handleSearch
  } = useDidUptateByDelayedSendQuery({
    cb: (v: string) => {
      userAction.resetUserPage();
      userAction.setQuery(v);
      userAction.resetCheckedUsers();
    }
  });

  const onSelectSkillGroup = (id: string) => {
    if (currentSkillGroup?.id === id) return;
    userAction.resetUserPage();
    userAction.resetCheckedUsers();
    userAction.setQuery("");
    handleSearch("");
    userAction.setCurrentSkillGroup(id);
  };

  const onSelectUser = (id?: string) => {
    id ? userAction.setSelectedUser(id) : userAction.setSelectedAllUsers();
  };

  const onNextPageUsers = () => userAction.changeUserPage(currentUserPage + 1);

  const onNextPageSkillgroup = () =>
    userAction.changeSkillGroupPage(currentSkillPage + 1);

  const closeModal = (parentModal?: Modals) => toggleModal(parentModal ?? null);

  const onEditUser = (id?: string) => {
    toggleModal(Modals.editUser);
    userAction.setUserEdited(id);
  };

  const onCloseModalUserEdit = () => {
    closeModal();
    userAction.cleanUserEdited();
  };

  const onConfirmModalUserEdit = () => {
    onCloseModalUserEdit();

    getUserList(currentUserPage);

    if (editedUserId === `${core.user.id}`) {
      getSkillGroupList(currentSkillPage);
    }
  };

  const onEditSkillGroup = (id?: string) => {
    toggleModal(Modals.editSkillgroup);
    userAction.setSkillGroupEdited(id);
  };

  const onCloseModalSkillgroupEdit = () => {
    closeModal();
    userAction.cleanSkillGroupEdited();
  };

  const onConfirmModalSkillgroupEdit = () => {
    onCloseModalSkillgroupEdit();
    getSkillGroupList(currentSkillPage);
  };

  const onDeleteSkillGroup = () => toggleModal(Modals.deleteSkillgroup);

  const onConfirmSkillgroupDelete = () => {
    closeModal();
    userAction.deleteSkillGroupAction({
      skillGroupId: editedSkillGroupId
    });
    userAction.cleanSkillGroupEdited();
  };

  const onCancelSkillgroupDelete = () => closeModal(Modals.editSkillgroup);

  const onEditCommonParams = () => toggleModal(Modals.editCommonParams);

  const onPassRestore = () => toggleModal(Modals.passRestore);

  const uncheckUsersAndClose = () => {
    closeModal();
    userAction.resetCheckedUsers();
  };

  const onConfirmEditCommonParams = () => {
    uncheckUsersAndClose();
    getUserList(currentUserPage);

    if (targetGroupUsersActionData.userIds.includes(`${core.user.id}`)) {
      getSkillGroupList(currentSkillPage);
    }
  };

  const onClosePassRestore = () => {
    editedUserId ? closeModal(Modals.editUser) : closeModal();
  };

  const onArchive = () => toggleModal(Modals.usersToArchive);

  const targetGroupUsersActionData: ITargetGroupUsersAction = {
    userIds: checkedUsers,
    isAll: allCheckBoxValue,
    skillGroupId: `${currentSkillGroup?.id}`,
    query: query
  };

  const editedUserRestorePassData = {
    userIds: [editedUserId],
    isAll: false,
    skillGroupId: `${currentSkillGroup?.id}`,
    query: ""
  };

  const onConfirmUsersToArchive = () => {
    uncheckUsersAndClose();
    userAction.archiveUsersAction(targetGroupUsersActionData);
    userAction.cleanUserEdited();
  };

  const closeModals = {
    [Modals.editUser]: onCloseModalUserEdit,
    [Modals.editSkillgroup]: onCloseModalSkillgroupEdit,
    [Modals.deleteSkillgroup]: onCancelSkillgroupDelete,
    [Modals.editCommonParams]: () => closeModal(),
    [Modals.passRestore]: onClosePassRestore,
    [Modals.usersToArchive]: () => closeModal()
  };

  return (
    <>
      <SettingsSection
        title="Управление сотрудниками"
        menu={
          <Preloader size="mini" show={skillGroups === null}>
            <GroupList
              skillGroups={skillGroups}
              currentSkillGroupPage={currentSkillPage}
              onAddUser={onEditUser}
              onEditSkillGroup={onEditSkillGroup}
              onSelectSkillgroup={onSelectSkillGroup}
              onNextPage={onNextPageSkillgroup}
            />
          </Preloader>
        }
        content={
          <Preloader size="mini" show={users === null}>
            <UserList
              users={users}
              currentSkillGroup={currentSkillGroup}
              all={allCheckBoxValue}
              currentUserPage={currentUserPage}
              onEditCommonParams={onEditCommonParams}
              onPassRestore={onPassRestore}
              onArchive={onArchive}
              onSelectUser={onSelectUser}
              searchText={searchAgentText}
              onSearch={handleSearch}
              onEdit={onEditUser}
              onNextPage={onNextPageUsers}
            />
          </Preloader>
        }
      />
      <ModalWindow
        open={!!activeModal}
        onCloseModal={closeModals[activeModal]}
        isButtonClose
      >
        <>
          {activeModal === Modals.editUser && (
            <UsersProfileModal
              administratorId={`${core.user.id}`}
              profileId={editedUserId}
              close={onConfirmModalUserEdit}
              onRestorePassword={onPassRestore}
            />
          )}
          {activeModal === Modals.editSkillgroup && (
            <SkillGroupModal
              onSave={onConfirmModalSkillgroupEdit}
              editMode={+editedSkillGroupId > 0}
              onDelete={onDeleteSkillGroup}
              skillId={editedSkillGroupId}
            />
          )}
          {activeModal === Modals.deleteSkillgroup && (
            <ConfirmModal
              onCancel={onCancelSkillgroupDelete}
              onConfirm={onConfirmSkillgroupDelete}
              text={["Выбранная группа будет удалена.", "Хотите продолжить?"]}
            />
          )}
          {activeModal === Modals.editCommonParams && (
            <UsersMultiProfileModal
              targetUsersAction={targetGroupUsersActionData}
              close={onConfirmEditCommonParams}
            />
          )}
          {activeModal === Modals.passRestore && (
            <RestorePassword
              targetUsersAction={
                editedUserId
                  ? editedUserRestorePassData
                  : targetGroupUsersActionData
              }
              onCancel={onClosePassRestore}
              onConfirm={
                editedUserId ? onClosePassRestore : uncheckUsersAndClose
              }
            />
          )}
          {activeModal === Modals.usersToArchive && (
            <ConfirmModal
              id="confirmModalUsersToArchive"
              onCancel={closeModal}
              onConfirm={onConfirmUsersToArchive}
              text={[
                "Выбранные сотрудники будут перенесены в архив.",
                "Хотите продолжить?"
              ]}
            />
          )}
        </>
      </ModalWindow>

      <Loading show={restorePasswordHandling} />
    </>
  );
};

export default UsersProvider;
