import { createSlice } from "@reduxjs/toolkit";
import { EDOWNLOAD_STATUS } from "../../ReactFeatures/Common/Enums/LoadingData.enums";
import {
  updateDialogList,
  setCsiSettings,
  resetDialogList,
  setChatInitialDataLoaded,
  setIntegration
} from "./Chat.actionCreators";

export const chatInitialState = {
  status: EDOWNLOAD_STATUS.IDLE,
  csiSettings: null,
  dialogs: [],
  integrations: {}
};

const ChatSlice = createSlice({
  name: "chat",
  initialState: chatInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateDialogList, (state, { payload }) => {
      state.dialogs = [...payload];
    }),
      builder.addCase(setCsiSettings, (state, { payload }) => {
        state.csiSettings = payload;
      }),
      builder.addCase(setChatInitialDataLoaded, (state) => {
        state.status = EDOWNLOAD_STATUS.FULFILLED;
      }),
      builder.addCase(resetDialogList, (state) => {
        state.dialogs = [];
      }),
      builder.addCase(setIntegration, (state, { payload }) => {
        state.integrations[payload.integrationType] = { ...payload?.data };
      });
  }
});

export default ChatSlice.reducer;
