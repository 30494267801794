import React, { FunctionComponent, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Button,
  Select,
  SwitcherContainer,
  isEmpty
} from "@omnichat/arm_ui_kit";

import { Input, Checkbox } from "../../../../Components";
import Radio from "../../../../Components/Radio";

import FormFieldConnector from "../../../../Components/FormFieldConnector";

import { ReactHookFormErrorHandler } from "../../../../Utils/HookForm.helper";

import { FieldTypes, IThematicPickerProps } from "./types";
import * as s from "./styles.module.scss";

import {
  useDynamicSchema,
  useThematicFieldsValidation
} from "../../Services/ThematicsPicker.service";

const RadioGroup = SwitcherContainer(Radio);
const CB = SwitcherContainer(Checkbox);

/**
 * Блок зависимых полей для выбора тематик
 */
const ThematicsPicker: FunctionComponent<IThematicPickerProps> = ({
  themesFields,
  onChange = () => {},
  onSave = () => {}
}) => {
  const validateData = useThematicFieldsValidation(themesFields);

  const schema = Yup.object().shape(validateData.reduce(useDynamicSchema, {}));

  const methods = useForm<any>({
    mode: "all",
    defaultValues: {},
    resolver: yupResolver(schema)
  });
  const { handleSubmit, reset, watch } = methods;

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name && value) {
        onChange([{ id: Number(name), value: value[name] }]);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    const checkboxFields = themesFields.filter(
      (f) => f.fieldType === FieldTypes.CHECKBOX && !f.value
    );
    if (!isEmpty(checkboxFields)) {
      const values = checkboxFields.map((c) => ({
        id: c.id,
        value: c.options?.find((o) => o.label === "false")?.value
      }));
      onChange(values);
    }
  }, [themesFields]);

  useEffect(() => {
    if (!isEmpty(themesFields)) {
      reset(
        themesFields.reduce((acc, f) => {
          acc[f.id] = f.value;
          return acc;
        }, {}),
        {
          keepErrors: true,
          keepDirty: true,
          keepTouched: true,
          keepIsValid: true
        }
      );
    }
  }, [themesFields]);

  return (
    <FormProvider {...methods}>
      <div>
        {!isEmpty(themesFields) &&
          themesFields.map((field, i) => (
            <div key={i} className={s["thematicPickerField"]}>
              {field.fieldType === FieldTypes.DROPDOWN && (
                <FormFieldConnector
                  required={!isEmpty(field.options)}
                  name={`${field.id}`}
                  actionText={field.description}
                  label={field.name}
                  Component={(p, s) => (
                    <Select
                      {...p}
                      isError={s.error}
                      isSearchable
                      label={""}
                      options={field.options || []}
                      selected={p.value ? [p.value] : []}
                      onSelectOption={(v) => p.onChange(v?.[0])}
                    />
                  )}
                />
              )}

              {field.fieldType === FieldTypes.CHECKBOX && (
                <FormFieldConnector
                  required={!isEmpty(field.options)}
                  label={field.name}
                  name={`${field.id}`}
                  actionText={field.description}
                  Component={(p) => (
                    <CB
                      {...p}
                      caption={field.name}
                      checked={field.options?.[0].value === p.value}
                      onChange={() =>
                        p.onChange(
                          field.options?.[0].value === p.value
                            ? field.options?.[1].value
                            : field.options?.[0].value
                        )
                      }
                    />
                  )}
                />
              )}

              {field.fieldType === FieldTypes.INPUT && (
                <FormFieldConnector
                  required
                  label={field.name}
                  name={`${field.id}`}
                  actionText={field.description}
                  Component={(p, s) => (
                    <Input
                      {...p}
                      isError={!!s.error}
                      placeholder="Не указано"
                      onChange={p.onChange}
                      value={p.value || ""}
                      chips={
                        field.templates?.map((t) => ({
                          name: t.name,
                          value: t.context
                        })) || []
                      }
                    />
                  )}
                />
              )}

              {field.fieldType === FieldTypes.RADIO && (
                <FormFieldConnector
                  required={!isEmpty(field.options)}
                  label={field.name}
                  name={`${field.id}`}
                  actionText={field.description}
                  Component={(p, s) => (
                    <>
                      {field?.options?.map((f, index) => (
                        <RadioGroup
                          key={index}
                          name={String(f.value)}
                          caption={f.label}
                          checked={p.value === f.value}
                          onClick={() => p.onChange(f.value)}
                        />
                      ))}
                    </>
                  )}
                />
              )}

              {field.fieldType === FieldTypes.TEXTAREA && (
                <FormFieldConnector
                  required
                  label={field.name}
                  name={`${field.id}`}
                  actionText={field.description}
                  Component={(p, s) => (
                    <Input
                      {...p}
                      rows={5}
                      isError={!!s.error}
                      placeholder="Введите текст"
                      onChange={p.onChange}
                      value={p.value || ""}
                      chips={
                        field.templates?.map((t) => ({
                          name: t.name,
                          value: t.context
                        })) || []
                      }
                    />
                  )}
                />
              )}
            </div>
          ))}
      </div>
      {onSave && (
        <div className={s["thematicPickerSave"]}>
          <Button
            onClick={handleSubmit(onSave, ReactHookFormErrorHandler)}
            type="underline"
            theme="default"
            text="Сохранить тему"
          />
        </div>
      )}
    </FormProvider>
  );
};

export default ThematicsPicker;
